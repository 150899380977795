// export const USERS_URL = process.env.NODE_ENV === 'development' ? 'http://192.168.1.123' : 'https://users.heineken.site';
// export const CONTENT_URL = process.env.NODE_ENV === 'development' ? 'http://192.168.1.133' : 'https://offbackend.sf-heineken.com';
// export const USERS_URL = process.env.NODE_ENV === 'development' ? 'http://192.168.1.123' : 'http://192.168.1.123';
// export const CONTENT_URL = process.env.NODE_ENV === 'development' ? 'http://192.168.1.133' : 'http://192.168.1.133';
// export const USERS_URL = process.env.NODE_ENV === 'development' ? 'http://users' : 'http://users';
// export const CONTENT_URL = process.env.NODE_ENV === 'development' ? 'http://v4' : 'http://v4';
export const USERS_URL = process.env.NODE_ENV === 'development' ? 'http://knaufspbusers' : 'https://u.knaufspb.ru';
export const CONTENT_URL = process.env.NODE_ENV === 'development' ? 'http://knaufspb' : 'https://c.knaufspb.ru';
export const USERS_API_URL = USERS_URL + '/api';
export const CONTENT_API_URL = CONTENT_URL + '/api';
