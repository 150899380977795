<template>
  <layout-auth>
    <div class="start wrapper formwidth">
      <div class="welcome form">
        <p class="LoginMessage">
          Добро пожаловать,
          <span v-html="fullName"></span>
        </p>
      </div>
      <transition name="fade">
        <form class="content-loader" v-if="contentLoader">
          <div v-if="!buttonsDisabled">
            <p>Доступно обновление контента</p>
            <button name="load_confirm" @click.prevent="getOfflineContent">Загрузить</button>
            <button name="load_postpone" @click.prevent="postpone" v-if="cncl">Позже</button>
            <div v-if="homeErrors.length">
              <p class="LoginError" :key="key" v-for="(error, key) in homeErrors">{{ error }}</p>
            </div>
          </div>
          <lottie
            v-if="phase !=='' "
            :options="defaultOptions"
            :height="70"
            :width="70"
            v-on:animCreated="handleAnimation"
            class="loader-graphics"
          />
        </form>
      </transition>
      <transition name="fade">
        <form class="form form-region" @submit.prevent="start" v-if="regionChooser">
          <p>Выберите доступный регион:</p>
          <sui-dropdown placeholder="Регион" selection :options="selectOptions" v-model="region" />
          <div class="InputfieldSubmit" v-bind:class="{ 'active': isLoading }">
            <button type="submit">Start</button>
          </div>
        </form>
      </transition>
      <div v-if="phase == '' || phase == 'contents'">
        <p>
          <a
            class="with-icon change-password"
            href="profile"
            @click.prevent="profile"
          >Изменить пароль</a>
        </p>
        <p>
          <a class="with-icon logout" href="logout" @click.prevent="logout">Выйти</a>
        </p>
      </div>
      <div v-if="phase !== '' && phase !== 'contents'">
        <p>
          <a class="with-icon cancel" href="home" @click.prevent="cancel">Отмена</a>
        </p>
      </div>
    </div>
  </layout-auth>
</template>

<script>
import Lottie from "vue-lottie";
import * as loaderAnimation from "@/assets/data.json";
import LayoutAuth from "@/Layouts/LayoutAuth.vue";
import * as CONFIG from "@/config";

export default {
  name: "Home",
  components: {
    lottie: Lottie,
    LayoutAuth
  },
  data() {
    return {
      region: this.$store.state.region,
      cncl: this.$store.state.modified,
      phase: "",
      buttonsDisabled: false,
      contentLoader: false,
      regionChooser: false,
      homeErrors: [],
      defaultOptions: {
        animationData: loaderAnimation.default,
        autoplay: false
      },
      isLoading: false
    };
  },
  mounted() {
    if (window.cordova) {
      if (window.navigator.onLine) {
        this.initPushNotifications();
        this.isLoading = true;
        if (this.$store.state.userData === null) this.logout(true);
        this.$store
          .dispatch("checkForMods")
          .then(result => {
            console.log("CheckforMods - success");
            this.isLoading = false;
            if (result) {
              this.contentLoader = true;
              this.buttonsDisabled = false;
            } else {
              this.loadContent();
            }
          })
          .catch(err => {
            console.log("false-checkformods:" + err);
            this.phase = "";
            this.isLoading = false;
            this.regionChooser = true;
            this.onLoadError(err);
            if (
              typeof err.response.status !== "undefined" &&
              err.response.status === 401
            ) {
              console.log("inside 401 if");
              this.logout(true);
            }
          });
      } else {
        this.loadContent();
      }
    } else {
      this.$store
        .dispatch("checkForMods")
        .then(needLoading => {
          if (needLoading) {
            this.contentLoader = true;
            this.buttonsDisabled = true;
            this.phase = "contents";
            this.loadContent();
          } else {
            this.phase = "";
            this.isLoading = false;
            this.regionChooser = true;
          }
        })
        .catch(err => {
          this.isLoading = false;
          this.onLoadError(err);
          if (err.response.status === 401) {
            this.logout();
          }
        });
    }
  },
  computed: {
    fullName() {
      return [
        this.$store.state.userData.firstName,
        this.$store.state.userData.lastName
      ].join("&nbsp;");
    },
    selectOptions() {
      return this.$store.state.userData.userRegion;
    }
  },
  methods: {
    loadContent(firstTime = false) {
      this.$store
        .dispatch("fetchTOC")
        .then(response => {
          this.phase = "";
          this.isLoading = false;
          this.regionChooser = true;
          if (window.cordova) {
            // console.log("data received: " + response);
            this.$store.commit("setTOC", { data: JSON.parse(response) });
            console.log("toc set in cordova: " + this.$store.state.toc);
          }
        })
        .catch(err => {
          this.phase = "";
          this.isLoading = false;
        });
    },
    initPushNotifications: function() {
      var push = PushNotification.init({
        ios: {
          alert: "true",
          badge: "true",
          sound: "true"
        }
      });

      var ctx = this;

      push.on("registration", function(data) {
        if (!ctx.$store.state.appToken) {
          ctx.$store.dispatch("registerAppUser", {
            appToken: data.registrationId
          });
        }
      });

      push.on("notification", function(data) {
        if (data.additionalData.foreground) {
          if (data.count) {
            cordova.plugins.notification.badge.set(data.count);
          }
          if (data.sound) {
            const media = new Media("media/chime.wav");
            media.play({ playAudioWhenScreenIsLocked: false });
          }
          if (data.message) {
            navigator.notification.confirm(
              data.message,
              function(btn) {
                if (btn == 1) {
                  ctx.$router.push({ path: "/" });
                }
              },
              "Обновление",
              ["Обновить", "Отмена"]
            );
          }
        } else {
          ctx.$router.push({ path: "/" });
        }
      });

      push.on("error", function(e) {
        console.log(e.message);
      });
    },
    handleAnimation: function(anim) {
      this.anim = anim;
      if (window.cordova) {
        this.anim.playSegments([0, 24], true);
        let borderref = $("#iconborder path");
        this.border = borderref.clone().insertAfter(borderref);
        this.border.attr("stroke", "#005f20");
        this.border.attr("stroke-dasharray", "0 1000");
        this.borderLength = Math.PI * 109;
      } else {
        this.anim.playSegments([25, 25 + 24], true);
      }
    },
    cancel() {
      if (window.cordova) {
        if (this.phase == "downloading") window.ft.abort();
        if (this.phase == "downloading" || this.phase == "unpacking")
          this.$store.dispatch("deletePackage");
        this.phase = "";
        this.isLoading = false;
        this.contentLoader = true;
        this.buttonsDisabled = false;
      }
    },
    start() {
      if (this.region !== null) {
        this.$store.commit("setActiveRegion", { region: this.region });
        this.$router.push({ path: "/home/" });
      }
    },
    profile() {
      this.$router.push({ path: "/profile/" });
    },
    logout(keep) {
      // console.log("logout-keepuser:" + keepUser);
      // Сброс параметра аутентификации
      // this.$store.commit("resetAuth", { auth: false });
      let keepUser = typeof keep !== "undefined" ? keep : false;
      this.$store
        .dispatch("logout", { keepUser: keepUser })
        .then(result => {
          console.log("logout-success" + result);
        })
        .catch(err => {
          console.log("logout-unsuccess: " + err);
        })
        .finally(() => {
          this.$router.push({ path: "/login/" });
        });
    },
    postpone() {
      if (this.$store.state.modified) {
        this.homeErrors.splice(0);
        this.contentLoader = false;
        this.loadContent();
      } else {
        this.homeErrors.splice(0);
        this.homeErrors.push("Загрузка контента необходима.");
      }
    },
    getOfflineContent() {
      if (window.navigator.onLine) {
        this.homeErrors.splice(0);
        this.phase = "packing";
        this.buttonsDisabled = true;
        this.$store.commit("increaseDownloadsStartedCount");

        this.$store
          .dispatch("getOfflineContent")
          .then(response => {
            if (this.phase == "packing") {
              this.$store.commit("setPackageTS", { ts: response.data.ts });
              if (window.downloadsStartedCount == 1) {
                this.downloadOfflineContent(response.data.ts);
              }
              this.$store.commit("decreaseDownloadsStartedCount");
            }
          })
          .catch(err => {
            this.isLoading = false;
            this.contentLoader = true;
            this.buttonsDisabled = false;
            this.phase = "";
            this.homeErrors.push("Соединение прервалось");
          });
      } else {
        this.homeErrors.splice(0);
        this.homeErrors.push("Необходимо подключение к сети");
      }
    },

    downloadOfflineContent(ts) {
      this.homeErrors.splice(0);
      var ctx = this;

      var uri = encodeURI(CONFIG.CONTENT_API_URL + "/offline?ts=" + ts);

      var fileURL = cordova.file.dataDirectory + "content.zip";
      window.ft = new FileTransfer();

      window.ft.onprogress = function(event) {
        if (event.lengthComputable) {
          ctx.border.attr(
            "stroke-dasharray",
            (event.loaded / event.total) * ctx.borderLength + " 1000"
          );
        }
      };

      ctx.phase = "downloading";
      ctx.anim.playSegments([25, 25 + 24], true);

      window.ft.download(
        uri,
        fileURL,
        function(entry) {
          ctx.phase = "unpacking";
          ctx.anim.playSegments([50, 50 + 24], true);
          zip.unzip(entry.toURL(), cordova.file.dataDirectory, function() {
            window.fileStorage.delete("content.zip");
            if (ctx.phase == "unpacking") {
              console.log("before:");
              console.log(ctx.$store.state.modified);
              ctx.$store.dispatch("sendContentLastUpdateTime", {
                ts: Math.round(+new Date() / 1000)
              });
              console.log("after:");
              console.log(ctx.$store.state.modified);
              ctx.loadContent(true);
            }
          });
        },
        function(error) {
          window.fileStorage.delete("content.zip");
          ctx.contentLoader = true;
          ctx.buttonsDisabled = false;
          ctx.phase = "";
        },
        true,
        {
          headers: {
            authorization: "Bearer " + this.$store.state.token
          }
        }
      );
    },

    onLoadError(err) {}
  }
};
</script>

<style lang="scss">
.start {
  .form-region,
  .content-loader {
    margin-bottom: 25px;
  }

  .content-loader div.loader-graphics {
    margin: 0 !important;
  }
}
</style>
