import 'jquery.panzoom'
import FastClick from 'fastclick'
import TweenMax from 'gsap'
import Swiper from 'swiper'
import FontFaceObserver from 'fontfaceobserver'
import './modernizr.min'
import 'picturefill'

import store from '../store';
import router from '../router';

import * as CONFIG from '@/config';

import axios from 'axios';

require('plyr/dist/plyr.css')


const IDLE = 1000 * 60 * 30;


(function () {
  if (typeof window.CustomEvent === "function") { return; }

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

window.onerror = function (msg, url, lineNo, columnNo, error) {
  // let data = { msg: msg, url: url, lineNo: lineNo, columnNo: columnNo, error: error };
  // store.dispatch("sendStatUpdate", { data: data }).finally(() => { return true });
  return false;
}

window.addEventListener('touchstart', () => {
  window.USER_IS_INTERACTING = true
  clearTimeout(window.activityTimer)
  window.activityTimer = setTimeout(Utils.appSemiLogout, IDLE)
}, false)

window.addEventListener('touchend', () => {
  window.USER_IS_INTERACTING = false
}, false)

window.addEventListener('mousedown', () => {
  window.USER_IS_INTERACTING = true
  clearTimeout(window.activityTimer)
  window.activityTimer = setTimeout(Utils.appSemiLogout, IDLE)
}, false);

window.addEventListener('mouseup', () => {
  window.USER_IS_INTERACTING = false
}, false)

window.addEventListener('keydown', () => {
  clearTimeout(window.activityTimer)
  window.activityTimer = setTimeout(Utils.appSemiLogout, IDLE)
}, false)

window.addEventListener('wheel', () => {
  window.USER_IS_INTERACTING = true
  clearTimeout(window.activityTimer)
  window.activityTimer = setTimeout(Utils.appSemiLogout, IDLE)
}, false)



$.event.special.doubletap = {
  bindType: 'touchend',
  delegateType: 'touchend',

  handle: function (event) {
    var handleObj = event.handleObj,
      targetData = jQuery.data(event.target),
      now = new Date().getTime(),
      delta = targetData.lastTouch ? now - targetData.lastTouch : 0,
      delay = delay == null ? 300 : delay;

    if (delta < delay && delta > 30) {
      targetData.lastTouch = null;
      event.type = handleObj.origType;
      ['clientX', 'clientY', 'pageX', 'pageY'].forEach(function (property) {
        event[property] = event.originalEvent.changedTouches[0][property];
      })

      // let jQuery handle the triggering of "doubletap" event handlers
      handleObj.handler.apply(this, arguments);
    } else {
      targetData.lastTouch = now;
    }
  }
};

class Utils {
  static appLogout() {
    Content.pageURL = '';
    store.dispatch("logout", { keepUser: false }).finally(() => { router.push({ path: "/" }); });
  }

  static appSemiLogout() {
    Content.pageURL = '';
    // Сброс параметра аутентификации
    store.commit('resetAuth', { auth: false });
    // Переход на экран Login.vue с сохранением данных в store
    store.dispatch("logout", { keepUser: true }).finally(() => { router.push({ path: "/" }); });
  }

  static isEmpty(a) {
    if (a.length > 0) return false
    return true
  }

  static disableSelection(pel) {
    pel.each((n, el) => {
      el.onselectstart = () => false
      el.unselectable = 'on'
      const $el = $(el)
      $el.css('-moz-user-select', 'none')
      $el.css('-webkit-user-select', 'none')
    })
  }

  static resolveURL(url) {

    const segs = url.split('/');
    if (segs[3] === 'files' || segs[3] === 'styles' || segs[3] === 'scripts') {
      const resolvedURL = window.cordova ? cordova.file.dataDirectory.slice(0, -1) + url : CONFIG.CONTENT_API_URL + url;
      return resolvedURL;
    }
  }

  static resolveURLs(container) {

    container.find('*').each((n, el) => {

      const $el = $(el)
      const tagName = $el[0].tagName.toLowerCase()
      let src = null
      let reURL = null
      if (tagName === 'img' || tagName === 'source') {
        reURL = /url\("?(.*?)"?\)/i
        src = $el.attr('src')
        if (typeof src !== 'undefined') {
          if (src.substr(0, 1) !== '/') src = `/${src}`

          $el.attr('src', Utils.resolveURL(src));

        }

        let srcset_str = $el.attr('srcset')
        if (typeof srcset_str !== 'undefined') {
          const srcset = srcset_str.split(",\n").map((el) => {
            const srcEl = el.split(' ');
            srcEl[0] = Utils.resolveURL(srcEl[0])
            return srcEl.join(' ');
          });
          srcset_str = srcset.join(",\n");
          $el.attr('srcset', srcset_str);
        }
      } else if (tagName === 'a') {
        src = $el.attr('href')
        if (typeof src !== 'undefined') {

          if (src.indexOf('.pdf') > 0) {
            if (src.substr(0, 1) !== '/') src = `/${src}`
            $el.attr('href', Utils.resolveURL(src));
          }
          // if (src.indexOf('.xlsx') > 0) {
          //   if (src.substr(0, 1) !== '/') src = `/${src}`
          //   $el.attr('href', Utils.resolveURL(src));
          // }
          if ($el.attr('class') == 'linkexit') {
            $el.on('click', (e) => {
              // Замена в state токена на произвольный
              // store.commit("resetToken");
              Utils.appLogout();
            })
          }
          // if ($el.attr('class') == 'linkchange') {
          //   $el.on('click', (e) => {
          //     Content.pageURL = '';
          //     // router.push({ path: "/" });
          //     if (!window.cordova) {
          //       window.location.href = '/';
          //     } else {
          //       window.location.href = cordova.file.applicationDirectory + 'www/index.html';
          //     }
          //   })
          // }
        }
      } else {
        src = $el.attr('data-href')
        if (typeof src !== 'undefined') {

          if (src.indexOf('.pdf') > 0) {
            if (src.substr(0, 1) !== '/') src = `/${src}`;
            $el.attr('data-href', Utils.resolveURL(src));
          }
          // if (src.indexOf('.xlsx') > 0) {
          //   if (src.substr(0, 1) !== '/') src = `/${src}`;
          //   $el.attr('data-href', Utils.resolveURL(src));
          // }
          if (src.indexOf('backid=') > 0) {
            if (src.substr(0, 1) !== '/') src = `/${src}`;
            $el.attr('data-href', Utils.resolveURL(src));
            $el.append("<span class='baloon' style='display:none'><span>");
            $el.on('click', (e) => {
              e.preventDefault();
              let $baloon = $el.find('.baloon');
              $baloon.css('display', 'block');
            })
          }
        }
      }

      src = $el.css('background-image')

      if (src !== "none") {


        reURL = /url\(\"?(.*?\:\/\/.*?)?(\/.*?)\"?\)/i;
        const matches = src.match(reURL);

        if (matches)
          if (matches !== null) {
            const a = document.createElement('a');
            a.href = matches[2];
            let newSrc = a.pathname;

            if (newSrc !== '') {
              if (newSrc.substr(0, 1) !== '/') newSrc = `/${newSrc}`
              $el.css('background-image', `url(${Utils.resolveURL(newSrc)})`);

            }
          }
      }
    })
  }

  static scaleDown(evt) {
    const $el = $(evt.currentTarget);
    TweenMax.set($el.children('.indicator'), {
      transformOrigin: '50% 50%'
    })
    TweenMax.to($el.children('.indicator'), 0.1, {
      scale: 0.9
    })
    TweenMax.set($el.children('.icon'), {
      transformOrigin: '50% 50%'
    })
    TweenMax.to($el.children('.icon'), 0.1, {
      scale: 0.9
    })
  }

  static scaleUp(evt) {
    const $el = $(evt.currentTarget);
    TweenMax.to($el.children('.indicator'), 0.3, {
      scale: 1
    })
    TweenMax.to($el.children('.icon'), 0.3, {
      scale: 1
    })
  }
}

class Swipers {
  static init() {
    this.swipers = [];
  }

  static resize() {
    if (typeof this.swipers !== 'undefined') {
      for (let i = 0; i < this.swipers.length; i += 1) {
        const sw = this.swipers[i];
        if (typeof sw.initialized !== 'undefined' && !sw.destroyed) {
          $(sw.el).removeAttr('style');
          sw.update()
        }
      }
    }
  }

  static makeOneSlideSwiper(id, options) {
    const newid = `.new ${id}`;
    let zoomButton;
    let initialSlide = 0;

    const $newid = $(newid);

    $newid.width($newid.parent().width());
    /*
    if (typeof options !== 'undefined') {
      const pageOffset = options.pageOffset ? options.pageOffset : 0;
    }
    */

    const scrollbar = $newid.append('<div class="swiper-scrollbar"></div>');
    scrollbar.css({
      left: '0',
      width: '100%'
    })

    $newid.after('<div class="swiper-next"></div>');
    $newid.after('<div class="swiper-prev"></div>');

    if (typeof options === 'undefined' || typeof options.noZoom === 'undefined') {
      zoomButton = $('<div class="zoom-button"></div>');
      $newid.after(zoomButton);
    }

    if (!Modernizr.objectfit) {
      $(`${newid} .img-container`).each((n, el) => {
        const $container = $(el);
        const imgUrl = $container.find('img').prop('src');
        if (imgUrl) {
          $container.css('backgroundImage', `url(${imgUrl})`);
          $container.addClass('object-fit-fix');
        }
      })
    }

    if (typeof Content.pageState[Content.pageURL] !== 'undefined') {
      if (typeof Content.pageState[Content.pageURL][id] !== 'undefined') {
        initialSlide = Content.pageState[Content.pageURL][id]
      }
    }

    const mySwiper = new Swiper(newid, {
      slideClass: 'swiper-slide',
      direction: 'horizontal',
      spaceBetween: 30,
      slidesPerView: 1,
      preventClicks: true,
      watchOverflow: true,
      preventClicksPropagation: true,
      initialSlide,
      scrollbar: {
        el: `${newid}>.swiper-scrollbar`,
        hide: true
      },
      navigation: {
        nextEl: $newid.siblings('.swiper-next'),
        prevEl: $newid.siblings('.swiper-prev')
      },
      on: {
        slideChange: function () {
          Content.pageState[Content.pageURL][id] = this.activeIndex
        }
      }
    })
    this.swipers.push(mySwiper);

    if (typeof options === 'undefined' || typeof options.noZoom === 'undefined') {
      zoomButton.click((e) => {
        e.preventDefault();
        const a = $(mySwiper.slides[mySwiper.activeIndex]).find('a').first();
        a.click();
      })
    }
  }

  static makeHorizontalSwiper(id) {


    const newid = '.new ' + id
    let initialSlide = 0



    const scrollbar = $('<div class="swiper-scrollbar"></div>')
    $(newid).after(scrollbar)
    $(newid).parents('.content-wrapper').append('<div class="swiper-next"></div>')
    $(newid).parents('.content-wrapper').append('<div class="swiper-prev"></div>')

    if (typeof Content.pageState[Content.pageURL] !== 'undefined') {
      if (typeof Content.pageState[Content.pageURL][id] !== 'undefined') {
        initialSlide = Content.pageState[Content.pageURL][id]
      }
    } else {
      Content.pageState[Content.pageURL] = []
    }

    const mySwiper = new Swiper(newid, {
      slideClass: 'swiper-slide',
      direction: 'horizontal',
      preventClicks: true,
      preventClicksPropagation: true,
      initialSlide,
      watchOverflow: true,
      slidesPerView: 5,
      spaceBetween: 6,
      scrollbar: {
        el: $(newid).siblings('.swiper-scrollbar'),
        hide: true
      },
      navigation: {
        nextEl: $('.content-wrapper.link-gallery .swiper-next'),
        prevEl: $('.content-wrapper.link-gallery .swiper-prev')
      },
      on: {
        slideChange: function () {
          Content.pageState[Content.pageURL][id] = this.activeIndex
        }
      },
      breakpoints: {
        767: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        1023: {
          slidesPerView: 2,
          spaceBetween: 6
        },
        1599: {
          slidesPerView: 4,
          spaceBetween: 6
        }
      }
    })
    this.swipers.push(mySwiper)
  }

  static makeFreeHorizontalSwiper(id) {
    //setTimeout(function () {
    const newid = '.new ' + id

    const scrollbar = $('<div class="swiper-scrollbar"></div>')
    $(newid).after(scrollbar)

    $(newid).parents('.content-wrapper').append('<div class="swiper-next"></div>')
    $(newid).parents('.content-wrapper').append('<div class="swiper-prev"></div>')

    if (!Modernizr.objectfit) {
      $(newid + ' .img-container').each((n, el) => {
        const $container = $(el)
        const imgUrl = $container.find('img').prop('src')
        if (imgUrl) {
          $container.css('backgroundImage', 'url(' + imgUrl + ')')
          $container.addClass('object-fit-fix')
        }
      })
    }

    if (typeof Content.pageState[Content.pageURL] === 'undefined') {
      Content.pageState[Content.pageURL] = []
    }


    const initialized = false
    const mySwiper = new Swiper(newid, {
      slideClass: 'inner',
      direction: 'horizontal',
      freeMode: true,
      preventClicks: true,
      preventClicksPropagation: true,
      slidesPerView: 'auto',
      watchOverflow: true,
      centerInsufficientSlides: true,
      scrollbar: {
        el: $(newid).siblings('.swiper-scrollbar'),
        hide: true
      },
      navigation: {
        nextEl: $('.page.sku-gallery .swiper-next'),
        prevEl: $('.page.sku-gallery .swiper-prev')
      },
      on: {
        setTranslate: function () {
          if (window.USER_IS_INTERACTING && initialized) {
            Content.pageState[Content.pageURL][id + '-translate'] = this.getTranslate()
          }
        },
        transitionEnd: function () {
          Content.pageState[Content.pageURL][id + '-translate'] = this.getTranslate()
        }
      }
    })
    Swipers.swipers.push(mySwiper);

    if (typeof Content.pageState[Content.pageURL] !== 'undefined') {
      if (typeof Content.pageState[Content.pageURL][id + '-translate'] !== 'undefined') {
        mySwiper.setTranslate(Content.pageState[Content.pageURL][id + '-translate'])
      }
    }
    //}, 50);
  }

  static makeDictNavSwiper() {
    let initialized = false;
    const mainSw = Swipers.makeVerticalScroller('#scrollarea');

    const newid = '.new .dictionaryNav';
    $(newid).append($('<div class="swiper-up"></div>'));
    $(newid).append($('<div class="swiper-down"></div>'));
    var dictNavSw = new Swiper(newid, {
      direction: 'vertical',
      freeMode: true,
      slideClass: 'dictSection',
      wrapperClass: 'wrapper',
      watchOverflow: true,
      slidesPerView: 'auto',
      navigation: {
        nextEl: $('.dictionaryNav .swiper-down'),
        prevEl: $('.dictionaryNav .swiper-up')
      },
    });
    Swipers.addSwiper(dictNavSw);

    const scrollDictNav = function (context) {
      const offset = -context.translate;
      let minDelta = 99999999999;
      let sel = undefined;
      $('.content-wrapper a.letterAnchor').each(function (n, el) {
        const $el = $(el);
        const elTop = $el.position().top;
        if (elTop <= offset) {
          const delta = offset - elTop;
          if (delta < minDelta) {
            minDelta = delta;
            sel = $el;
          }
        }
      });
      if (sel) {
        $('.dictionaryNav').find('.selected').removeClass('selected');
        const letterLi = $('.dictionaryNav li[data-letter=' + sel.attr('id') + ']');
        letterLi.addClass('selected');
        let letterOffset = letterLi.position().top - 47;
        if (dictNavSw.virtualSize - letterOffset < dictNavSw.size) letterOffset = dictNavSw.virtualSize - dictNavSw.size;

        dictNavSw.setTransition(500);
        dictNavSw.setTranslate(-letterOffset);
      }

    };

    mainSw.on('setTranslate', function () {
      if (initialized) {
        Content.pageState[Content.pageURL]['#scrollarea-translate'] = this.getTranslate();
      }
      scrollDictNav(this);
    });
    mainSw.on('transitionEnd', function () {

      Content.pageState[Content.pageURL]['#scrollarea-translate'] = this.getTranslate();
      scrollDictNav(this);
    });

    $(newid).find('li[data-letter]').each(function (n, el) {
      let $el = $(el);
      $el.click(function (event) {
        $el = $(event.currentTarget);
        const $scrollEl = $('.content-wrapper').find('#' + $el.data('letter'));
        let scrollPos = -$scrollEl.position().top;
        if (mainSw.virtualSize + scrollPos < mainSw.size) scrollPos = mainSw.size - mainSw.virtualSize;
        mainSw.setTransition(500);
        mainSw.setTranslate(scrollPos);
        Content.pageState[Content.pageURL]['#scrollarea-translate'] = scrollPos;
        $('.dictionaryNav').find('.selected').removeClass('selected');
        $el.addClass('selected');
      })
    });



    if (typeof Content.pageState[Content.pageURL] !== 'undefined') {
      if (typeof Content.pageState[Content.pageURL]['#scrollarea-translate'] !== 'undefined') {
        mainSw.setTranslate(Content.pageState[Content.pageURL]['#scrollarea-translate'])
      } else {
        mainSw.setTranslate(0);
      }
    }

    initialized = true;


  }

  static makeVerticalScroller(id, options) {
    const newid = '.new ' + id
    $(newid).find('.content-wrapper').wrap('<div class="swiper-wrapper"></div>')
    $(newid).append('<div class="swiper-scrollbar"></div>')
    if (typeof options !== 'undefined' && typeof options.scrollbarRight !== 'undefined') {
      $(newid + ' .swiper-scrollbar').css('right', options.scrollbarRight)
    }
    let initialized = false
    const mySwiper = new Swiper(newid, {
      slideClass: 'content-wrapper',
      preventClicks: true,
      preventClicksPropagation: true,
      direction: 'vertical',
      slidesPerView: 'auto',
      freeMode: true,
      watchOverflow: true,
      scrollbar: {
        el: newid + '>.swiper-scrollbar',
        hide: true
      },
      on: {
        setTranslate: function () {
          if (window.USER_IS_INTERACTING && initialized) {
            Content.pageState[Content.pageURL][id + '-translate'] = this.getTranslate();
          }
        },
        transitionEnd: function () {
          Content.pageState[Content.pageURL][id + '-translate'] = this.getTranslate();
        }
      },
      mousewheel: true
    })
    this.swipers.push(mySwiper)
    initialized = true

    if (Content.pageState[Content.pageURL] != null) {
      if (Content.pageState[Content.pageURL][id + '-translate'] != null) {
        mySwiper.setTranslate(Content.pageState[Content.pageURL][id + '-translate'])
      }
    } else {
      Content.pageState[Content.pageURL] = []
    }
    return mySwiper;
  }

  static makeHorizontalScroller(id, options) {
    const newid = '.new ' + id
    $(newid).find('.content-wrapper').wrap('<div class="swiper-wrapper"></div>')
    $(newid).append('<div class="swiper-scrollbar"></div>')
    if (typeof options.scrollbarBottom !== 'undefined') {
      $(newid + ' .swiper-scrollbar').css('bottom', options.scrollbarBottom)
    }
    let initialized = false
    const mySwiper = new Swiper(newid, {
      slideClass: 'content-wrapper',
      preventClicks: true,
      preventClicksPropagation: true,
      direction: 'horizontal',
      slidesPerView: 'auto',
      freeMode: true,
      watchOverflow: true,
      centerInsufficientSlides: true,
      scrollbar: {
        el: newid + '>.swiper-scrollbar',
        hide: true
      },
      on: {
        setTranslate: function () {
          if (window.USER_IS_INTERACTING && initialized) {
            Content.pageState[Content.pageURL][id + '-translate'] = this.getTranslate()
          }
        },
        transitionEnd: function () {
          Content.pageState[Content.pageURL][id + '-translate'] = this.getTranslate()
        }
      },
      mousewheel: true
    });

    mySwiper.update();
    this.swipers.push(mySwiper)
    initialized = true

    if (Content.pageState[Content.pageURL] != null) {
      if (Content.pageState[Content.pageURL][id + '-translate'] != null) {
        mySwiper.setTranslate(Content.pageState[Content.pageURL][id + '-translate'])
      }
    } else {
      Content.pageState[Content.pageURL] = []
    }
    return mySwiper;
  }

  static updateVerticalArrows(n) {
    $('.page .swiper-down').not('.swiper-down-' + n).hide()
    $('.page .swiper-down-' + n).show()
    $('.page .swiper-up').not('.swiper-up-' + n).hide()
    $('.page .swiper-up-' + n).show()
  }

  static resetZoom($el, animate) {
    $el.panzoom('option', 'disablePan', false)
    $el.panzoom('reset', animate)
    $el.removeClass('zoomed')
    $el.parents('.swiper-zoom-container').removeClass('zoomed')
    setTimeout(() => {
      $el.panzoom('option', 'disablePan', true)
    }, 200)
  }

  static zoom(e) {
    const $el = $(e.currentTarget)
    if ($el.hasClass('zoomed')) {
      Swipers.resetZoom($el, true)
    } else {
      $el.parents('.swiper-zoom-container').addClass('zoomed')
      $el.panzoom('option', 'disablePan', false)
      const o = $(e.currentTarget).position()
      const focalPoint = {
        clientX: e.clientX - o.left,
        clientY: e.clientY - o.top
      }
      setTimeout(() => {
        $el.addClass('zoomed')
        $el.panzoom('zoom', 3, {
          focal: focalPoint,
          animate: true
        })
      }, 100)
    }
  }

  static makeZoomSwiper(id, id1) {
    const newid = '.new ' + id
    const newid1 = '.new ' + id1
    $(id).append('<div class="swiper-scrollbar"></div>')
    $(id + ' .swiper-scrollbar').css({
      left: '0',
      width: '100%'
    })

    const mySwiper1 = new Swiper(newid1, {
      allowTouchMove: false,
      slidesPerView: 1,
      preventClicks: true,
      preventClicksPropagation: true,
      watchOverflow: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      spaceBetween: 0,
      initialSlide: Content.pageInitSlide ? Content.pageInitSlide : 0
    })
    this.swipers.push(mySwiper1)

    $(newid).after('<div class="swiper-next"></div>')
    $(newid).after('<div class="swiper-prev"></div>')

    if (!Modernizr.objectfit) {
      $(newid + ' .img-container').each((n, el) => {
        const $container = $(el)
        const imgUrl = $container.find('img').prop('src')
        if (imgUrl) {
          $container.css('backgroundImage', 'url(' + imgUrl + ')')
          $container.addClass('object-fit-fix')
        }
      })
    }
    $(newid + ' .img-container').on('dblclick', Swipers.zoom)
    Utils.disableSelection($(newid + ' .img-container'))
    $(newid + ' .img-container').on('doubletap', Swipers.zoom)

    const initialSlide = Content.pageInitSlide ? Content.pageInitSlide : 0
    const mySwiper = new Swiper(newid, {
      direction: 'horizontal',
      spaceBetween: 30,
      slidesPerView: 1,
      preventClicks: true,
      preventClicksPropagation: true,
      watchOverflow: true,
      initialSlide,
      scrollbar: {
        el: '.new .swiper-scrollbar',
        hide: true
      },
      navigation: {
        nextEl: $(newid).siblings('.swiper-next'),
        prevEl: $(newid).siblings('.swiper-prev')
      },
      on: {
        slideChange: function () {
          $('.img-container.zoomed').each((n, iel) => {
            Swipers.resetZoom($(iel), false)
          })
          mySwiper1.slideTo(this.activeIndex)
          let url = Content.pageURL
          url = url.substr(0, url.indexOf('#slide'))
          url += '#slide' + (this.activeIndex + 1)
          window.history.replaceState(url, '', url)
          Content.pageInitSlide = this.activeIndex

          Swipers.updateVerticalArrows(this.activeIndex)
        }
      }
    })
    this.swipers.push(mySwiper)

    Content.breakpoint = window.matchMedia('(min-width:1024px)')
    let vSwipers = []
    const breakpointChecker = () => {
      setTimeout(() => {
        $('.content-wrapper.slider .swiper-slide').each((n, el) => {
          let $el = $(el)
          if ($el.hasClass('allow-horizontal') && Content.breakpoint.matches === true) {
            for (let i = 0; i < vSwipers.length; i += 1) {
              const sw = vSwipers[i]
              if ($(sw.el) === $el) {
                if ((typeof sw.initialized !== 'undefined') && (!sw.destroyed)) {
                  sw.destroy(true, true)
                }
              }
            }
            vSwipers = []

            $el = $(el)
            let b = $el.find('.swiper-wrapper').children()
            b.unwrap('.swiper-wrapper')
            b = $('.page.slider .swiper-up-' + n)
            b.remove()
            b = $('.page.slider .swiper-down-' + n)
            b.remove()
          } else if ($el.find('.figure-wrapper').length > 1 && !$el.hasClass('.allow-horizontal') && $el.children('.swiper-wrapper').length === 0) {
            $el.wrapInner($('<div class="swiper-wrapper"></div>'))
            $('.content-wrapper.slider').before('<div class="swiper-up swiper-up-' + n + '"></div>')
            $('.content-wrapper.slider').before('<div class="swiper-down swiper-down-' + n + '"></div>')

            const vSwiper = new Swiper($el, {
              slideClass: 'figure-wrapper',
              direction: 'vertical',
              spaceBetween: 40,
              navigation: {
                nextEl: '.swiper-down-' + n,
                prevEl: '.swiper-up-' + n
              }
            })

            vSwipers.push(vSwiper)

            Swipers.updateVerticalArrows(Content.pageInitSlide)
          }
        })
        this.resize()
      }, 50)
    }

    Content.breakpointHandler = breakpointChecker
    Content.breakpoint.addListener(Content.breakpointHandler)
    Content.breakpointHandler()

    $(newid + ' .img-container').panzoom({
      minScale: 3,
      maxScale: 3,
      disablePan: true
    })
  }

  static makeChannelPrimaryMenuSwiper() {
    let $newid = $('.new .content-wrapper .chapters');
    if (!$newid.length) $newid = $('.content-wrapper .chapters');
    $newid.after('<div class="swiper-prev"></div><div class="swiper-next"></div>');

    let initialSlide = 0;
    if (typeof Content.pageState[Content.pageURL] !== 'undefined' && typeof Content.pageState[Content.pageURL].selectedChapter !== 'undefined') {
      initialSlide = Content.pageState[Content.pageURL].selectedChapter;
    }
    const chPrimarySwiper = new Swiper($newid[0], {
      direction: 'horizontal',
      spaceBetween: 20,
      slideClass: 'chapter-link',
      slidesPerView: 'auto',
      initialSlide,
      watchSlidesVisibility: true,
      watchOverflow: true,
      navigation: {
        nextEl: $newid.siblings('.swiper-next'),
        prevEl: $newid.siblings('.swiper-prev')
      },
      on: {
        slideChange: function () {
          Content.pageState[Content.pageURL].selectedChapter = this.activeIndex;
        }
      }
    })

    this.swipers.push(chPrimarySwiper);
    return chPrimarySwiper;
  }

  static makeChannelSecondaryMenuSwiper() {
    let $newid = $('.new .content-wrapper .chapters-secondary');
    if (!$newid.length) $newid = $('.content-wrapper .chapters-secondary');
    $newid.after('<div class="swiper-sec-prev"></div><div class="swiper-sec-next"></div>');

    let initialSlide = 0;
    if (typeof Content.pageState[Content.pageURL] !== 'undefined' && typeof Content.pageState[Content.pageURL].selectedSecondaryMenuItem !== 'undefined') {
      initialSlide = Content.pageState[Content.pageURL].selectedSecondaryMenuItem;
    }
    const chSecondarySwiper = new Swiper($newid[0], {
      direction: 'horizontal',
      spaceBetween: 20,
      slideClass: 'chapter-link',
      slidesPerView: 'auto',
      initialSlide,
      centerInsufficientSlides: true,
      watchSlidesVisibility: true,
      watchOverflow: true,
      navigation: {
        nextEl: $newid.siblings('.swiper-sec-next'),
        prevEl: $newid.siblings('.swiper-sec-prev')
      },
      on: {
        slideChange: function () {
          Content.pageState[Content.pageURL].selectedChapter = this.activeIndex;
        }
      }
    });

    this.swipers.push(chSecondarySwiper);
    return chSecondarySwiper;
  }

  static makeChapterMenuSwiper() {
    function ajustSize() {
      if (chmSwiper.params.slidesPerView === 'auto') {
        const containerWidth = $(chmSwiper.el).width();
        chmSwiper.params.width = Math.floor((containerWidth + 20) / (126 + 20)) * (126 + 20) - 20;
      } else {
        chmSwiper.params.width = 126;
      }
      if (typeof Content.pageState[Content.pageURL].selectedTab !== 'undefined') {
        Content.showDropdown($(chmSwiper.slides[Content.pageState[Content.pageURL].selectedTab]), false);
        chmSwiper.params.slidesOffsetAfter = 0;
        chmSwiper.update();

      }
    }

    let newid = '.new .content-wrapper .tabs'
    if ($(newid).length === 0) {
      newid = '.content-wrapper .tabs'
    }
    $(newid).after('<div class="swiper-next"></div><div class="swiper-prev"></div>');

    let initialSlide = 0;
    if (typeof Content.pageState[Content.pageURL] === 'undefined') Content.pageState[Content.pageURL] = []
    if (typeof Content.pageState[Content.pageURL].offsetTab !== 'undefined') {
      initialSlide = Content.pageState[Content.pageURL].offsetTab;
    }

    const chmSwiper = new Swiper(newid, {
      direction: 'horizontal',
      spaceBetween: 40,
      slideClass: 'tab',
      slidesPerView: 1,
      breakpointsInverse: true,
      breakpoints: {
        768: {
          slidesPerView: 'auto',
          spaceBetween: 20,
        }
      },
      watchOverflow: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
      shortSwipes: false,
      initialSlide,
      navigation: {
        nextEl: $(newid).siblings('.swiper-next'),
        prevEl: $(newid).siblings('.swiper-prev')
      },
      on: {
        slideChange: function () {
          if (this.params.slidesPerView === 1) {
            const slide = $(this.slides[this.activeIndex]);
            Content.showDropdown(slide);
            Content.pageState[Content.pageURL].offsetTab = Content.pageState[Content.pageURL].selectedTab = this.activeIndex;
          } else {
            const slideNum = Content.pageState[Content.pageURL].selectedTab;
            if (typeof slideNum !== 'undefined' && !this.params.silentTransition) {
              const slide = $(this.slides[slideNum]);
              Content.showDropdown(slide, false, true);
            }
          }
        },
        resize: ajustSize,
      },
    });
    this.swipers.push(chmSwiper);
    ajustSize();

    setTimeout(function () {
      if (chmSwiper.params.slidesPerView === 1) {
        if (initialSlide === 0) {
          const slide = $(chmSwiper.slides[initialSlide]);
          Content.showDropdown(slide, true, false);
        }
      } else {
        const slideNum = Content.pageState[Content.pageURL].selectedTab;
        if (typeof slideNum !== 'undefined') {
          const slide = $(chmSwiper.slides[slideNum]);
          Content.showDropdown(slide, true, true);
        }
      }
    }, 150);
  };

  static makeDDListSwiper(ddlist, n) {
    ddlist.wrapInner($('<div class="swiper-wrapper" />'));
    const downArrow = $('<div class="swiper-down swiper-down-' + n + '"></div>');
    ddlist.after(downArrow);
    downArrow.hide();
    const ddlistSwiper = new Swiper(ddlist, {
      slideClass: 'section',
      direction: 'vertical',
      slidesPerView: 'auto',
      watchOverflow: true,
      spaceBetween: 3,
      navigation: {
        nextEl: '.swiper-down-' + n
      }
    })

    this.swipers.push(ddlistSwiper);
    return ddlistSwiper;
  }

  static addSwiper(sw) {
    this.swipers.push(sw)
  }
}

class Menu {
  constructor() {
    this.menuVisible = false
    this.topMenuVisible = false
    this.menu = this
  }

  static hideMenu() {
    if (typeof this.menuVisible === 'undefined' || this.menuVisible) {
      $('#menu').addClass('hide')
      this.menuVisible = false
      $('li.menu-item').removeClass('chapter-selected')
      $('li.menu-item, li.menu-item li').removeClass('selected')
    }
  }

  static showMenu() {
    if (typeof this.menuVisible === 'undefined' || !this.menuVisible) {
      $('#menu').removeClass('hide')
      this.menuVisible = true
    }
  }

  static hideTopMenu() {
    if (this.topMenuVisible) {
      this.collapseTopMenu(() => {
        $('#topmenu').addClass('hide')
        this.topMenuVisible = false
      })
    }
  }

  static showTopMenu() {
    if (!this.topMenuVisible) {
      $('#topmenu').removeClass('hide')
      this.topMenuVisible = true
    }
  }

  static collapseTopMenu(callback) {
    $('#topmenu .expanded').each((n, el) => {
      const $tab = $(el)
      Menu.collapseTopMenuTab($tab)
    })
    $('#topmenu .expanded').removeClass('expanded')
    if ($('#topmenu').hasClass('expanded')) {
      $('#topmenu').removeClass('expanded')
    }

    if (typeof callback !== 'undefined') {
      callback()
    }
  }

  static collapseMenu() {
    if ($('#menu').hasClass('visible')) {
      TweenMax.set($('.menu-controller>.icon'), {
        backgroundPosition: '0px 0px'
      })
      TweenMax.to($('.menu-controller>.icon'), 0.5, {
        backgroundPosition: '0px -46px'
      })
    }
    $('#menu').removeClass('visible')
    $('#menu').removeClass('expanded')
  }

  static expandTopMenuTab($tab) {
    const $sel = $tab.find('.menu-section')
    const speed = 15 / $sel.length * 0.08;
    $sel.each((n, el) => {
      const $section = $(el)
      TweenMax.to($section, speed, {
        yPercent: '0',
        display: 'block',
        autoAlpha: 1,
        delay: (n) * speed / 2
      })
    })
  }

  static collapseTopMenuTab($tab, collapseTopMenu) {
    const $sections = $tab.find('.menu-section')
    const l = $sections.length
    TweenMax.killChildTweensOf($tab, {
      yPercent: false,
      autoAlpha: false
    })
    const speed = 15 / l * 0.08;
    $sections.each((n, el) => {
      const $section = $(el)
      TweenMax.to($section, speed, {
        yPercent: '-100',
        display: 'none',
        autoAlpha: 0,
        delay: (l - 1 - n) * speed / 2
      })
    })
    if (typeof collapseTopMenu !== 'undefined' && collapseTopMenu) {
      TweenMax.delayedCall(l * speed * 0.6, () => {
        $('#topmenu').removeClass('expanded')
      })
    }
  }

  static selectMenuItem(chapter, tab, section) {
    const menuItem = $('li.menu-item').eq(chapter)
    $('li.menu-item').removeClass('selected')
    menuItem.addClass('selected')
    let topMenuItem = $('.topmenu-chapter').eq(chapter)
    $('.topmenu-chapter').removeClass('selected')
    topMenuItem.addClass('selected')

    topMenuItem = $('.topmenu-chapter').eq(chapter)
    $('.topmenu-chapter').removeClass('selected')
    topMenuItem.addClass('selected')

    let goShowTopMenu = false
    let menuTab

    if (typeof tab !== 'undefined') {
      goShowTopMenu = true
      menuTab = topMenuItem.find('li.menu-tab').eq(tab)
      topMenuItem.find('li.menu-tab').removeClass('selected')
      menuTab.addClass('selected')

      const sw = topMenuItem.find('.tabs').first().get(0).swiper;
      sw.slideTo(tab);
    }

    if (typeof section !== 'undefined') {
      goShowTopMenu = true

      const menuSection = menuTab.find('li.menu-section' + (section + 1));
      $('#topmenu').find('li.menu-section').removeClass('selected');
      menuSection.addClass('selected')
    }

    if (goShowTopMenu) {
      this.showTopMenu()
    } else {
      this.hideTopMenu()
    }
  }

  static buildMenu() {


    const contents = store.state.toc;
    // Отработка ошибки с отсутствующими данными меню в store
    if (contents === null) { router.push({ path: "/" }); }

    $('#menu').html('<button class="menu-controller"><div class="icon"></div></button><ul></ul><a class="back-home-button" data-restore-state>Home</a>')

    $('#menu .back-home-button').on('click', (evt) => {
      const target = $(evt.currentTarget)
      const url = target.attr('href')
      const restoreState = typeof target.data('restore-state') !== 'undefined'
      Content.goToDestination(url, restoreState)
      evt.preventDefault()
      return false
    })

    const menu = $('#menu ul')
    const topMenu = $('#topmenu')
    topMenu.html('')
    this.selectedMenuItem = -1

    window.leftMenuItemCount = 0
    for (let i = 0; i < contents.length; i += 1) {
      const chapter = contents[i]
      // if (chapter.region.length == 0 || chapter.region.indexOf(store.state.region) >= 0) { // Использовалось на случай отображения пункта меню только для конкретного региона
      const menuChapter = $(`<li class="menu-item menu-item${i + 1} ${chapter.type}"><img src="${chapter.icon}"/><span>${chapter.title}</span></li>`);
      if (typeof chapter.url !== 'undefined') {
        menuChapter.attr('data-href', chapter.url)
      }
      if (typeof chapter.target !== 'undefined') {
        menuChapter.attr('target', chapter.target)
      }
      menu.append(menuChapter)
      window.leftMenuItemCount++;
      // }

      //      if (chapter.type === 'primary') {
      const menuChapterUL = $("<div class='topmenu-chapter topmenu-chapter" + (i + 1) + "'></div>")

      const tabs = chapter.tabs;
      if (typeof tabs !== 'undefined') {
        for (let j = 0; j < tabs.length; j += 1) {

          const tab = tabs[j];

          //if (typeof tab.region === 'undefined' || tab.region.indexOf(Content.regionCode) >= 0) {

          const menuTab = $(`<li class="menu-tab of-${tabs.length} menu-tab${(j + 1)} menu-chapter-${(i + 1)}-tab"><span>${tab.title}</span></li>`)
          if (typeof tab.url !== 'undefined') {
            menuTab.attr('data-href', tab.url)
            if (typeof tab.target !== 'undefined') menuTab.attr('target', tab.target)
          }

          const sections = tab.sections
          if (typeof sections !== 'undefined' && sections.length > 0) {
            const menuTabUL = $('<ul></ul>')
            for (let k = 0; k < sections.length; k += 1) {

              const section = sections[k];
              if (typeof section.region === 'undefined' || section.region.indexOf(Content.regionCode) >= 0) {
                let addClass = ''
                if (typeof section !== 'undefined' && typeof section.icon === 'undefined') addClass = ' compact'
                const menuSection = $("<li class='menu-section menu-section" + (k + 1) + addClass + "'><span>" + section.title + '</span></li>')
                if (typeof section.url !== 'undefined') {
                  menuSection.attr('data-href', section.url)
                }
                menuTabUL.append(menuSection)
              }

            }
            menuTabUL.wrapInner($('<div class="wrapper"></div>'));
            menuTab.append(menuTabUL);
          }

          menuChapterUL.append(menuTab)
          //}

        }
        menuChapterUL.wrapInner('<div class="tabs"><ul class="swiper-wrapper"></ul></div>');
        menuChapterUL.append('<div class="swiper-next swiper-next' + (i + 1) + '"></div><div class="swiper-prev swiper-prev' + (i + 1) + '"></div>');
      }
      topMenu.append(menuChapterUL);

      const menuTabs = menuChapterUL.find('.tabs').first();

      if (menuTabs.length > 0) {
        const sw = new Swiper(menuTabs, {
          slideClass: 'menu-chapter-' + (i + 1) + '-tab',
          direction: 'horizontal',
          spaceBetween: 10,
          slidesPerView: 1,
          breakpointsInverse: true,
          breakpoints: {
            768: {
              slidesPerView: 2
            },
            1024: {
              slidesPerView: 3
            }
          },
          on: {
            resize: function () {

              const ind = $(this.$wrapperEl.find('.menu-tab.selected')).index();
              if (ind >= 0) this.slideTo(ind);
            }
          },
          navigation: {
            nextEl: '.swiper-next' + (i + 1),
            prevEl: '.swiper-prev' + (i + 1)
          },
          watchOverflow: true,
        });

        const menuTabsDropdowns = menuTabs.find('.menu-tab>ul');
        menuTabsDropdowns.each(function (n, el) {

          $(el).append($('<div class="swiper-down"></div>'));
          new Swiper($(el), {
            slideClass: 'menu-section',
            wrapperClass: 'wrapper',
            direction: 'vertical',
            spaceBetween: 0,
            slidesPerView: 'auto',
            freeMode: false,
            watchOverflow: true,
            resistance: true,
            resistanceRatio: 0,
            navigation: {
              nextEl: '.swiper-down',
            },
          });

          const menuSections = $(el).find('.menu-section');
          menuSections.each(function (n, sel) {
            TweenMax.set($(sel), {
              yPercent: '-100',
              display: 'none',
              autoAlpha: 0
            })
          });

        });
      }
      //      }
    }

    $('<style id="leftMenuHeightStyle" type="text/css"></style>').html(`#menu.visible>ul { height: calc(${window.leftMenuItemCount} * 46px); }`).appendTo('head')

    Utils.resolveURLs(menu)
    Utils.resolveURLs(topMenu)

    $('li.menu-tab>span').click({
      o: this
    }, (e) => {
      const $el = $(e.currentTarget).parent()

      const $sel = $el.siblings('.expanded').not($el)
      if ($sel.length) {
        $sel.removeClass('expanded')
        $sel.each((n, el) => {
          const $tab = $(el)
          e.data.o.collapseTopMenuTab($tab)
        })
      }
      const hasSubmenu = typeof $el.data('href') === 'undefined'
      if (hasSubmenu) {
        $el.toggleClass('expanded')
        if ($el.hasClass('expanded')) {
          $('#topmenu').addClass('expanded')
          e.data.o.expandTopMenuTab($el)
        } else {
          e.data.o.collapseTopMenuTab($el, true)
        }
      }
    })

    $('li.menu-tab>span,li.menu-section>span').hover(
      (evt) => {
        const $el = $(evt.currentTarget)
        $el.parent().addClass('hover')
      },
      (evt) => {
        const $el = $(evt.currentTarget)
        $el.parent().removeClass('hover')
      }
    )

    $('.menu-controller').on('click', (evt) => {
      $(evt.currentTarget).blur()
      if ($('#menu').hasClass('visible')) {
        if ($('#menu').hasClass('expanded')) {
          Menu.collapseMenu()
        } else {
          $('#menu').addClass('expanded')
          TweenMax.set($('.menu-controller>.icon'), {
            backgroundPosition: '-46px 0px'
          })
          TweenMax.to($('.menu-controller>.icon'), 0.5, {
            backgroundPosition: '0px 0px'
          })
          if (typeof this.menuTimer !== 'undefined') clearTimeout(this.menuTimer)
        }
      } else {
        TweenMax.set($('.menu-controller>.icon'), {
          backgroundPosition: '-46px -46px'
        })
        TweenMax.to($('.menu-controller>.icon'), 0.5, {
          backgroundPosition: '-46px 0px'
        })
        $('#menu').addClass('visible')
        if (typeof this.menuTimer !== 'undefined') clearTimeout(this.menuTimer)
      }
    })

    $('li.menu-item>img, li.menu-tab>span, li.menu-item>span, li.menu-section>span').on('click', (e) => {
      e.preventDefault()
      let url = $(e.target).parent().data('href')
      if (typeof url === 'undefined') url = $(e.target).parent().data('data-href')
      if (typeof url !== 'undefined') {
        const target = $(e.target).parent().attr('target')
        if (target == '_blank') {
          window.open(url);
        } else {
          const event = new CustomEvent('navigate', {
            detail: {
              url
            },
            bubbles: true,
            cancelable: true
          });
          window.dispatchEvent(event)
        }
      }
    })
  }
}

class Content {
  static init() {
    this.regionCode = parseInt(store.state.region, 10);
    this.channel = store.state.channel;

    this.cache = {};
    this.initialized = false;
    this.plyrs = [];

    FastClick.attach(document.body);

    $(window).resize(Content.onResize);
    $(window).on('orientationchange', this.onResize);

    window.onpopstate = (event) => {
      if (event.state !== null && typeof event.state === 'string') this.loadPage(event.state);
    }

    this.pageState = [];
    this.initialized = true;

    this.menu = Menu;
    this.menu.buildMenu();

    $(window).trigger('resize');

    window.addEventListener('navigate', (e) => {
      this.goToDestination(e.detail.url)
    })

    let startURL
    if (window.cordova) {
      startURL = '/home'
    } else {
      startURL = window.location.pathname + window.location.hash
      if (startURL === '/') startURL = '/home'
    }

    clearTimeout(window.activityTimer)
    window.activityTimer = setTimeout(Utils.appSemiLogout, IDLE)

    var fontA = new FontFaceObserver('FuturaPTCondWeb');
    var fontB = new FontFaceObserver('FuturaPTCondWeb', {
      weight: 'bold'
    });

    Promise.all([fontA.load(), fontB.load()]).then(() => {
      this.goToDestination(startURL)
    });


  }

  static onTOCLoaded(response) {
    store.state.toc = response.data
  }

  static onResize() {
    Swipers.resize()
    $('.menu-controller>.icon').css('transform', 'scale(' + $('.menu-controller').outerWidth() / 46 + ')')

    if (($(window).height() - $('#menu').outerHeight()) < 46 * window.leftMenuItemCount) {
      let menuItemHeight = ($(window).height() - $('#menu').outerHeight()) / window.leftMenuItemCount;

      $('#menu ul img').css('height', menuItemHeight);
    } else {
      $('#menu ul img').css('height', '')
    }
  }

  static goToDestination(url, restoreState, forceHistoryBack = false) {
    if (typeof restoreState === 'undefined') restoreState = false
    if (!restoreState) Content.pageState[url] = []

    window.history.pushState(url, '', url)
    this.loadPage(url, forceHistoryBack)
  }

  static loadPage(name, forceHistoryBack = false) {
    let urlToGo = name;

    // обнуление текстового значения в "кружочке" прелоадера 
    $("#loader .loaderamount").html("0%");
    const hashPos = urlToGo.indexOf('#slide')
    if (hashPos > 0) {
      Content.pageInitSlide = parseInt(urlToGo.substr(hashPos + 6), 10) - 1;
      urlToGo = urlToGo.substr(0, hashPos);
    }
    //if (urlToGo.substr(-1)==='/') urlToGo = urlToGo.substr(0,urlToGo.length-1);

    if (Content.pageURL !== urlToGo) {
      Content.pageURL = urlToGo
      Content.forceHistoryBack = forceHistoryBack;
      let cacheID = urlToGo

      if (window.cordova) {
        let fileName = cacheID.split('/').filter(e => e).join('.')
        const fallbackFilename = fileName + '(0).json';
        if (Content.regionCode) fileName += '(' + Content.regionCode + ').json'; else fileName = fallbackFileName;

        const promise = window.fileStorage.load(fileName)
        promise.done((data) => {
          Content.onPageLoaded(JSON.parse(data), forceHistoryBack)
        })
        promise.fail(() => {
          const promise = window.fileStorage.load(fallbackFilename)
          promise.done((data) => {
            Content.onPageLoaded(JSON.parse(data), forceHistoryBack)
          })
          promise.fail(() => { })
        });
      } else {
        if (typeof this.cache[cacheID] === 'undefined') {
          $('#loader').removeClass('hide');
          axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
          axios.defaults.headers.Authorization = 'Bearer ' + store.state.token;
          this.cache[cacheID] = axios.get(Content.pageURL === '/home/' ? Content.channel : Content.pageURL, {
            params: {
              region: Content.regionCode
            }
          });
        }
        this.cache[cacheID]
          .then(response => {
            Content.onPageLoaded(response.data)
          })
          .catch(err => {
            Content.onLoadError(err);
          });
      }
    }
  }

  static onPageLoaded(data) {
    Content.selectMenuItems();

    $('.video-player').remove();
    $('.shield').remove();

    const container = $('<div class="page new"></div>');
    container.css('opacity', 0)

    if (data.html) {
      container.html(data.html);
    }

    const preloader = require('preloader');

    $('#content .page').first().addClass('old');

    Content.loadImages(container, data);
  }

  static loadImages(container, data) {
    // console.log('start loading images');
    Utils.resolveURLs(container);

    const preloader = require('preloader');

    if (typeof data.files !== 'undefined' && data.files.length) {
      const filesLoader = preloader({ xhrImages: false });
      let loaderamount = 0;
      data.files.map(item => {
        if (item.url.indexOf(".mp4") < 0)
          filesLoader.add(Utils.resolveURL(item.url));
      });
      filesLoader.on('complete', function () { Content.preloadDone(container, data); });
      filesLoader.on('progress', function (progress) {
        if (!window.cordova && Number(progress) > Number(loaderamount)) {
          loaderamount = Number(progress);
          $("#loader .loaderamount").html(Math.round(loaderamount * 85) + "%");
        }
      });
      filesLoader.load();
    }
    else Content.preloadDone(container, data);
  }

  static preloadDone(container, data) {
    container.find('.back-button').click((e) => {
      window.history.back()
      e.preventDefault()
    })

    // Замена ссылки кнопки закрытия страницы (крестика) на ссылку "Назад"
    if (Content.forceHistoryBack) {
      container.find('.header .close-button').removeAttr('href');
      container.find('.header .close-button').removeAttr('hidden');
    }

    container.find('.header .close-button:not([href])').click((e) => {
      window.history.back()
      e.preventDefault()
    })

    if (typeof Content.breakpoint !== 'undefined') Content.breakpoint.removeListener(Content.breakpointHandler)
    Swipers.init()

    const $menu = $('#menu')
    const $stage = $('#stage')

    $stage.removeAttr('class')

    if (data && typeof data.template !== 'undefined') {
      $stage.addClass(data.template)
      container.addClass(data.template)

      $stage.addClass(data.theme)
      container.addClass(data.theme);

      if (data.theme === 'image') {
        $menu.removeClass('background');
      } else {
        $menu.addClass('background');
      }

      switch (data.template) {
        case 'visit-stage-element':
        case 'basic-page':
        case 'brand':
          Content.fixLists()
          Content.markEmptyParagraphs()
          Content.makeQATable()
          Content.makeControllerIconsTable()
          Content.makeCollapsibleColumns()
          Content.makeVideoPlayers()
          break
        default:
      }

      if (data.is_locked === true) {
        container.addClass('locked')
        container.find('.content-wrapper').append($('<div class="under-construction">СТРАНИЦА НАХОДИТСЯ В&nbsp;РАЗРАБОТКЕ!</div>'))
      }

      if (data.template === 'channel') { Menu.hideMenu(); } else { Menu.showMenu(); }
    }
    if (data) {
      if (typeof (data.backhref) === 'undefined') {
        $menu.find('.back-home-button').addClass('hide')
      } else {
        $menu.find('.back-home-button').removeClass('hide')
        $menu.find('.back-home-button').attr('href', data.backhref)

      }
    }
    container.find('a[href],[data-href]').not("[target='_blank']").on('click', (evt) => {
      const target = $(evt.currentTarget)
      let url = target.attr('href')
      let forceHistoryBack = typeof url !== 'undefined'
      if (!forceHistoryBack) url = target.data('href');
      forceHistoryBack = typeof target.data('backhere') !== 'undefined';
      forceHistoryBack = url.indexOf("backhere") > 0;

      const restoreState = typeof target.data('restore-state') !== 'undefined';
      store.commit("deletePageFromUpdates", { pageID: target.data('pageid') });
      Content.goToDestination(url, restoreState, forceHistoryBack);

      evt.preventDefault()
      return false
    })

    container.find("[target='_blank']").on('click', (evt) => {
      const target = $(evt.currentTarget)
      // console.log(evt)
      let url = target.attr('href')
      if (typeof url === 'undefined') url = target.data('href')
      if (window.cordova) {
        cordova.InAppBrowser.open(url, "_blank", "location=no,toolbar=yes,closebuttoncaption=Назад,toolbarcolor=#ffffff,closebuttoncolor=#0f4210,enableViewportScale=yes,toolbarposition=top");
        evt.preventDefault()
      } else {
        window.open(url);
        evt.preventDefault()
      }
      store.commit("deletePageFromUpdates", { pageID: target.data('pageid') });
    })

    $('#content').append(container);
    picturefill();


    if ($('#content .page').length > 1) {
      const oldPage = $('#content .page').first();

      TweenMax.to(oldPage[0], 0.2, {
        force3D: false,
        opacity: 0,
        onComplete: function () {
          for (let i = 0; i < Content.plyrs.length; i += 1)  Content.plyrs[i].destroy();

          $('#content .page').first().remove()
          $('style.customStyle:not(.new)').remove()
          $('link.customStylesheet:not(.new)').remove()

          Content.loadStyles(container, data);
        }
      });
    } else {
      Content.loadStyles(container, data);
    }
  }

  static loadStyles(container, data) {
    if (typeof data.stylesheets !== 'undefined' && data.stylesheets.length) {
      if (window.cordova) {
        let stylesheetsCount = data.stylesheets.length;
        data.stylesheets.map(item => {
          var cssLink = $("<link class='customStylesheet new' rel='stylesheet' type='text/css' href='" + Utils.resolveURL(item.url) + "'>");
          $("head").append(cssLink);
          stylesheetsCount--;
          if (stylesheetsCount == 0) Content.revealNewPage(container, data);
        });

      } else {
        const preloader = require('preloader');
        const stylesheetsLoader = preloader({ xhrImages: false });
        let loaderamount = 0;
        data.stylesheets.map(item => {
          stylesheetsLoader.add(
            Utils.resolveURL(item.url),
            {
              onComplete: function () {

                var cssLink = $("<link class='customStylesheet new' rel='stylesheet' type='text/css' href='" + this.url + "'>");
                $("head").append(cssLink);
              }
            }
          );
        });
        stylesheetsLoader.on('complete', function () { Content.revealNewPage(container, data); loaderamount = 0; });
        stylesheetsLoader.on('progress', function (progress) {
          loaderamount = Number(progress) > loaderamount ? Number(progress) : loaderamount;
          $("#loader .loaderamount").html(Math.round(loaderamount * 10) + 85 + "%");
        });
        stylesheetsLoader.load();
      }
    }
    else Content.revealNewPage(container, data);

  }

  static revealNewPage(container, data) {
    if (typeof data.customStyles !== 'undefined') {
      for (let i = 0; i < data.customStyles.length; i++) {
        $('<style class="customStyle new" type="text/css"></style>').html(data.customStyles[i]).appendTo('head')
      }
    }

    if (typeof data.scripts !== 'undefined' && data.scripts.length) {
      if (window.cordova) {
        let scriptsCount = data.scripts.length;
        data.scripts.map(item => {
          window.resolveLocalFileSystemURL(Utils.resolveURL(item.url),
            function (fileEntry) {
              fileEntry.file(function (file) {
                var reader = new FileReader();
                reader.onloadend = function () {
                  eval(this.result);
                  scriptsCount--;
                  if (scriptsCount == 0) Content.finalize(container, data);
                };
                reader.readAsText(file);
              });
            },
            function (e) {
            }
          );
        });

      } else {
        setTimeout(function () {
          const preloader = require('preloader');
          const scriptsLoader = preloader({ xhrImages: false });
          let loaderamount = 0;
          data.scripts.map(item => {
            scriptsLoader.add(
              Utils.resolveURL(item.url),
              {
                onComplete: (scriptString) => {

                  eval(scriptString)
                }
              }
            )
          });
          scriptsLoader.on('complete', function () { Content.finalize(container, data); loaderamount = 0; });
          scriptsLoader.on('progress', function (progress) {
            loaderamount = Number(progress) > loaderamount ? Number(progress) : loaderamount;
            $("#loader .loaderamount").html(Math.round(loaderamount * 5) + 95 + "%");
          });
          scriptsLoader.load();
        }, 200);
      }
    }
    else Content.finalize(container, data);
  }

  static finalize(container, data) {
    if (typeof data.customScripts !== 'undefined') {
      for (let i = 0; i < data.customScripts.length; i++) {
        eval(data.customScripts[i]);
      }
    }

    setTimeout(function () {
      $('#content .page').removeClass('new');
      $('style.customStyle').removeClass('new');
      $('link.customStylesheet').removeClass('new');

      $('#loader').addClass('hide')
      Menu.collapseMenu()
      Menu.collapseTopMenu()


      TweenMax.to(container[0], 0.5, {
        force3D: false,
        opacity: 1
      })
    }, 200);

  }

  static onLoadError(err) { 
    if (
      typeof err.response.status !== "undefined" &&
      err.response.status === 401
    ) {
      console.log("onLoadError 401");
      Utils.appSemiLogout();
    }
    console.log(err);
  }

  static makeCollapsiblePanels() {
    $('.page.new .collapsible').each((n, el) => {
      const $el = $(el);
      const $p = $el.find('.panel').first();
      const oh = $p.height();
      $p.attr('data-height', oh);
      $p.css('height', 0);
    });
    $('.page .collapsible .trigger').click((e) => {
      const $el = $(e.currentTarget).parent();
      const $p = $el.find('.panel').first();
      if ($el.hasClass('expanded')) {
        TweenMax.to($p, 0.3, {
          height: 0,
          ease: Power2.easeInOut,
          onComplete: () => { Swipers.resize(); },
        });
        $el.removeClass('expanded');
      } else {
        TweenMax.to($p, 0.3, {
          height: $p.data('height'),
          ease: Power2.easeInOut,
          onComplete: () => { Swipers.resize(); },
        });
        $el.addClass('expanded');
      }
    });
  }


  static fixLists() {
    $('ol').each((n, el) => {
      const $el = $(el);
      const val = typeof $el.attr('start') !== 'undefined' ? parseFloat($el.attr('start')) - 1 : 0;
      $el.css('counter-increment', 'myCounter ' + val)
    });
  }

  static markEmptyParagraphs() {
    $('.column p').filter((n, el) => $(el).html() === '&nbsp;').addClass('empty')
  }


  static resizeScrollArea() {
    const newHeight = $('#content').height() -
      $('.header').outerHeight(true) -
      $('#stage .footer').outerHeight(true)
    $('#scrollarea').height(newHeight)

    $('.content-wrapper.image-slides').height($('#scrollarea').height())
  }

  static selectMenuItems() {
    let url = Content.pageURL
    const hashPos = url.indexOf('#slide')
    if (hashPos > 0) {
      url = url.substr(0, hashPos)
    }
    let chapter
    let tab
    let section


    for (let i = 0; i < store.state.toc.length; i += 1) {
      if (url.indexOf(store.state.toc[i].url) >= 0) {
        chapter = i
      }
      if (typeof store.state.toc[i].tabs !== 'undefined') {
        let tabs = store.state.toc[i].tabs
        for (let j = 0; j < tabs.length; j += 1) {
          if (url.indexOf(tabs[j].url) >= 0) {
            tab = j
            chapter = i
          }
          if (typeof tabs[j].sections !== 'undefined') {
            let sections = tabs[j].sections
            for (let k = 0; k < sections.length; k += 1) {
              if (url.indexOf(sections[k].url) >= 0) {
                section = k
                tab = j
                chapter = i
              }
/*               if (typeof sections[k].pages !== 'undefined') { 
                let pages = sections[k].pages

                for (let l = 0; l < pages.length; l += 1) {
                  if (url.indexOf(pages[l].url) >= 0) {
                    section = k
                    tab = j
                    chapter = i
                  }
                }
              }
 */            }
          }
        }
      }
    }
    Menu.selectMenuItem(chapter, tab, section)
  }

  static makeRoundLinks() {
    const ll = $('.new .largebutton')
    ll.each((n, el) => {
      const $el = $(el)
      const indicator = $el.children().eq(0)
      indicator.empty()
      indicator.addClass('indicator')

      const icon = $el.children().eq(1)
      icon.addClass('icon')
      $el.hover(
        (evt) => {
          const $iel = $(evt.currentTarget)
          $iel.addClass('hover')
        },
        (evt) => {
          const $iel = $(evt.currentTarget)
          $iel.removeClass('hover');
          Utils.scaleUp(evt);
        }
      );
      $el.mousedown(Utils.scaleDown);
      $el.on('touchstart', Utils.scaleDown);
      $el.mouseup(Utils.scaleUp);
      $el.on('touchend', Utils.scaleUp);
    });
  }

  static makeDropdowns(makeDDlistSwipers) {
    if (typeof makeDDlistSwipers === 'undefined') makeDDlistSwipers = false;
    let controller;
    const ddlistSwipers = [];
    let dd = $('.new.chapter .dropdown');
    if (dd.length === 0) dd = $('.chapter .dropdown');
    dd.each((n, el) => {
      const $el = $(el);
      const ddlist = $el.parents('.tabs').siblings('.dropdown-list-' + n);

      if (makeDDlistSwipers) {
        ddlist.removeAttr('style');
        ddlistSwipers.push(Swipers.makeDDListSwiper(ddlist, n));
      }

      controller = $el.find('.roundlink');
      const links = ddlist.find('li.section');

      for (let i = 0; i < links.length; i += 1) {
        const li = links.eq(i);
        li.hover(
          (evt) => {
            const $iel = $(evt.currentTarget).children('.button');
            $iel.addClass('hover');
          },
          (evt) => {
            const $iel = $(evt.currentTarget).children('.button');
            $iel.removeClass('hover');
          }
        )
        li.mousedown(Utils.scaleDown);
        li.on('touchstart', Utils.scaleDown);
        li.mouseup(Utils.scaleUp);
        li.on('touchend', Utils.scaleUp);
        TweenMax.set(li, {
          yPercent: '-100',
          autoAlpha: 0
        });
        li.hide();
      }
      controller.on('click', (evt) => {
        const $iel = $(evt.currentTarget);
        Content.pageState[Content.pageURL].selectedTab = $iel.parent().index();
        const swiper = $iel.parents('.tabs')[0].swiper;
        Content.pageState[Content.pageURL].offsetTab = swiper.activeIndex;
        if ($iel.hasClass('dropdown-controller')) {
          Content.showDropdown($iel.parent(), true, !makeDDlistSwipers);
        }
      });
    });

    return ddlistSwipers;
  }

  static makeChapterMenu() {
    const chmSwiper = Swipers.makeChapterMenuSwiper()

    Content.breakpoint = window.matchMedia('(min-width:768px)')
    let ddlistSwipers = []
    Content.breakpointHandler = (e) => {
      setTimeout(() => {
        if (e.matches === true) {
          if (ddlistSwipers.length > 0) {
            let b = $('.content-wrapper.chapter .swiper-down')
            b.remove()
            b = $('.content-wrapper.chapter .dropdown-list .swiper-wrapper').children()
            b.unwrap('.swiper-wrapper')
            for (let i = 0; i < ddlistSwipers.length; i += 1) {
              const ddlsw = ddlistSwipers[i]
              if ((typeof ddlsw.initialized !== 'undefined') && (!ddlsw.destroyed)) ddlsw.destroy(true, true)
            }
            ddlistSwipers = []
          }
          this.makeDropdowns(false);
          const chmSwiper = $('.content-wrapper.chapter .tabs')[0].swiper;
          chmSwiper.update();
        } else if (e.matches === false) {
          ddlistSwipers = Content.makeDropdowns(true)
        }
      }, 100)
    }

    Content.breakpoint.addListener(Content.breakpointHandler)
    Content.breakpointHandler(Content.breakpoint)
  }

  static makeChannelMenu() {
    let chPrimarySwiper
    let chSecondarySwiper
    Content.breakpointHandler = (e) => {
      setTimeout(() => {
        if (e.matches === true) {
          if (typeof chPrimarySwiper !== 'undefined' && !chPrimarySwiper.destroyed) {
            let b
            chPrimarySwiper.destroy(true, true)
            b = $('.content-wrapper.channel .swiper-next')
            b.remove()
            b = $('.content-wrapper.channel .swiper-prev')
            b.remove()
          }
          if (typeof chSecondarySwiper !== 'undefined' && !chSecondarySwiper.destroyed) {
            let b
            chSecondarySwiper.destroy(true, true)
            b = $('.content-wrapper.channel .swiper-sec-next')
            b.remove()
            b = $('.content-wrapper.channel .swiper-sec-prev')
            b.remove()
          }
        } else if (e.matches === false) {
          if (typeof Content.pageState[Content.pageURL] === 'undefined') Content.pageState[Content.pageURL] = []
          if (typeof Content.pageState[Content.pageURL].selectedChapter === 'undefined') {
            Content.pageState[Content.pageURL].selectedChapter = 0
          }
          chPrimarySwiper = Swipers.makeChannelPrimaryMenuSwiper()
          chSecondarySwiper = Swipers.makeChannelSecondaryMenuSwiper()
        }
      }, 150)
    }
    Content.breakpoint = window.matchMedia('(min-width:768px)')
    Content.breakpoint.addListener(Content.breakpointHandler)
    Content.breakpointHandler(Content.breakpoint)

    console.log('update markers');
    console.log(store.state.updates);
    console.log(Object.keys(store.state.updates).indexOf(Content.regionCode.toString()));

    let chapterUpdates = 0;
    if (store.state.updates) {
      if (store.state.updates.hasOwnProperty(Content.regionCode)) {
        for (let c = 0; c < store.state.toc.length; c++) {
          const menuItem = $('.chapter-link-' + c);
          let updCount = 0;
          console.log(store.state.updates.hasOwnProperty(Content.regionCode));
          if (store.state.updates[Content.regionCode].hasOwnProperty(c)) {
            updCount = store.state.updates[Content.regionCode][c].length;
          }
          if (updCount) {
            const badge = $(`<div class="badge">${updCount}</div>`)
            menuItem.append(badge);
            chapterUpdates++;
          }
        }
      }
    }
    if (window.cordova) cordova.plugins.notification.badge.set(chapterUpdates);
  }

  static showDropdown($el, expand = true, needReposition = false) {
    const swiper = $el.parents('.tabs')[0].swiper;
    let $sel;
    if (expand) $sel = $el.siblings('.selected').not($el)
    else {
      $sel = $el;
    }
    if ($sel.length) {
      $sel.each((n, el) => {
        const $iel = $(el)
        $iel.removeClass('selected')
        const ddlist = $iel.parents('.tabs').siblings('.dropdown-list-' + $iel.data('dropdown'))
        TweenMax.killChildTweensOf(ddlist, {
          yPercent: false,
          autoAlpha: false
        })

        $('.swiper-down-' + $iel.data('dropdown')).hide()
        const links = ddlist.find('li.section')
        for (let i = 0; i < links.length; i += 1) {
          const li = links.eq(links.length - i - 1)
          TweenMax.set(li, {
            yPercent: '-100',
            autoAlpha: 0
          })
        }
      })
    }

    if (expand) {
      const hasSubmenu = typeof $el.data('href') === 'undefined'
      if (hasSubmenu) $el.addClass('selected')
      $el.show()
      if (hasSubmenu) {
        const ddlist = $el.parents('.tabs').siblings('.dropdown-list-' + $el.data('dropdown'))
        ddlist.removeAttr('style');

        if (needReposition) {
          ddlist.offset({ left: $el.offset().left + $el.width() / 2 - 25 });
          let compRight = 0;
          let top = 999999;
          let columns = 0;
          const ddsections = ddlist.find('.section');
          ddsections.show();
          ddsections.each((n, el) => {
            const $el = $(el);
            const right = $el.offset().left + $el.outerWidth(true);
            if ($el.offset().top < top) {
              columns += 1;
            }
            top = $el.offset().top;
            if (right > compRight) compRight = right;
          });
          compRight -= 40; // не учитывать margin
          const compWidth = compRight - $el.offset().left;

          const rows = Math.ceil(ddsections.length / columns);
          ddlist.height(rows * 60);

          const contentWrapper = $el.parents('.content-wrapper');
          const overflow = compRight - contentWrapper.offset().left - contentWrapper.width();
          if (overflow > 0) {
            const slidesDelta = Math.ceil((overflow + 20) / (126 + 20));
            const delta = slidesDelta * (126 + 20);
            const newLeft = parseInt(ddlist.css('left'), 10) - delta;
            swiper.params.silentTransition = true;

            //const additionalSpaceAfter = Math.floor((compWidth - 126) / (126 + 20)) * (126 + 20);
            const toAccomodate = $el.index() * (126 + 20) + compWidth - swiper.slides.length * (126 + 20) - 20;
            const additionalSpaceAfter = toAccomodate > 0 ? Math.ceil(toAccomodate / (126 + 20)) * (126 + 20) : 0;
            swiper.params.slidesOffsetAfter = additionalSpaceAfter;
            swiper.update();
            swiper.slideTo(swiper.activeIndex + slidesDelta);
            TweenMax.to(ddlist[0], 0.3, {
              left: newLeft
            });
            swiper.params.silentTransition = false;
          }
        }
        ddlist.parent().append(ddlist)
        $('.swiper-down-' + $el.data('dropdown')).show()
        const links = ddlist.find('li.section')
        for (let i = 0; i < links.length; i += 1) {
          const li = links.eq(i)
          li.show()
        }
        TweenMax.staggerTo(links, 0.05, {
          yPercent: '0',
          autoAlpha: 1,
        }, 0.1)

      }
    }
  }

  /*static makeResponsiveTables() {
    $('.page.new table.responsive-table').find('td:nth-child(4)').each((n, el) => {
      const $el = $(el)
      const tr = $("<tr class='qa-link'><td colspan='4'>" + $el.html() + '</td></tr>')
      $el.parent().after(tr)
    })
  }*/

  static makeQATable() {
    $('.page.new table.qa').find('td:last-child').each((n, el) => {
      const $el = $(el);
      const $pel = $el.parent('tr');
      const scount = $pel.children('td').length;
      const tr = $("<tr class='qa-link'><td colspan='" + scount + "'>" + $el.html() + "</td></tr>");
      $el.parent().after(tr);
    })
  }

  static makeCollapsibleColumns(id) {
    const newid = '.new ' + id;

    $(newid).find('td:nth-child(1)').each((n, el) => {
      const $el = $(el);
      const $pel = $el.parent('tr');
      const scount = $pel.children('td').length;
      const s = $el.html();
      if (s !== '&nbsp;') {
        const $nel = $("<tr class='row-title'><td class='rule_above'>&nbsp;</td><td class='rule_above' colspan='" + (scount - 1) + "'>" + s + '</td></tr>')
        $pel.before($nel)
      }
    })
    const $pel = $(newid).find('thead > tr').first();
    const scount = $pel.children('th').length;
    const title = $pel.find('th:nth-child(1)').first().text().trim() + '/' + $pel.find('th:nth-child(2)').first().text().trim();
    const $nel = $("<tr class='row-title'><th class='rule_above'>&nbsp;</th><th class='rule_above' colspan='" + (scount - 1) + "'>" + title + '</td></tr>');
    $pel.before($nel);
    $(newid).addClass('collapse-c1-2');
  }

  static makeHorizontalScrollers() {

    $('.page.new .hscroll').each((n, el) => {
      const $el = $(el)
      $el.wrap('<div class="hscroll-container hscroll-container-' + n + '"><div class="swiper-wrapper"></div></div>')
      const $container = $el.parents('.hscroll-container').first()
      const scrollbar = $container.append('<div class="swiper-scrollbar"></div>')
      scrollbar.css({
        left: '0',
        width: '100%'
      })
      const sw = new Swiper($container, {
        direction: 'horizontal',
        freeMode: true,
        slideClass: 'hscroll',
        slidesPerView: 'auto',
        watchOverflow: true,
        centerInsufficientSlides: false,
        scrollbar: {
          el: '.swiper-scrollbar',
          hide: true
        }
      })
      Swipers.addSwiper(sw)
    })
  }

  static makeVideoPlayers() {
    $('.video-player').appendTo('.page.new')

    $('.video-player video').each((n, el) => {
      const $el = $(el)
      $el.attr('preload', 'auto')
      //this.plyrs.push(new Plyr($el.get(0)))
    })
    $('.video-player').addClass('hidden');

    TweenMax.set('.video-player', {
      autoAlpha: 0
    })


    $('.video-player').prependTo('#stage')

    $('.video-player').first().before("<div class='shield hidden'></div>")

    $('.video-player .close-button,.shield').click(() => {

      const video = Content.activeVideo.find('video')
      video.get(0).pause();

      TweenMax.to(Content.activeVideo, 0.3, {
        autoAlpha: 0
      })
      Content.activeVideo.addClass('hidden')
      $('.shield').addClass('hidden')
    })

    $('.video-button').click((evt) => {

      const $el = $(evt.currentTarget)
      const id = $el.data('video')

      Content.activeVideo = $('.' + id)
      const video = Content.activeVideo.find('video')
      video.get(0).currentTime = 0
      video.get(0).pause()
      TweenMax.to(Content.activeVideo, 0.3, {
        autoAlpha: 1,
        onComplete: () => {
          if (Modernizr.videoautoplay) {
            video.get(0).play()
          }
        }
      })
      Content.activeVideo.removeClass('hidden')
      $('.shield').removeClass('hidden')
    })
  }
}


window.fileStorage = {
  save: function (name, data) {
    var deferred, fail, gotFileEntry, gotFileSystem, gotFileWriter
    deferred = $.Deferred()
    fail = (function (_this) {
      return function (error) {
        return deferred.reject(error)
      }
    })(this)
    gotFileSystem = (function (_this) {
      return function (dir) {
        return dir.getFile(name, {
          create: true,
          exclusive: false
        }, gotFileEntry, fail)
      }
    })(this)
    gotFileEntry = (function (_this) {
      return function (fileEntry) {
        return fileEntry.createWriter(gotFileWriter, fail)
      }
    })(this)
    gotFileWriter = (function (_this) {
      return function (writer) {
        writer.onwrite = function () {
          return deferred.resolve()
        }
        writer.onerror = fail
        return writer.write(data)
      }
    })(this)
    // window.requestFileSystem(window.LocalFileSystem.PERSISTENT, data.length || 0, gotFileSystem, fail);
    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, gotFileSystem, fail)
    return deferred.promise()
  },
  load: function (name) {
    var deferred, fail, gotFile, gotFileEntry, gotFileSystem
    deferred = $.Deferred()
    fail = (function (_this) {
      return function (error) {
        return deferred.reject(error)
      }
    })(this)
    gotFileSystem = (function (_this) {
      return function (dir) {
        return dir.getFile(name, {
          create: false,
          exclusive: false
        }, gotFileEntry, fail)
      }
    })(this)
    gotFileEntry = (function (_this) {
      return function (fileEntry) {
        return fileEntry.file(gotFile, fail)
      }
    })(this)
    gotFile = (function (_this) {
      return function (file) {
        var reader
        reader = new FileReader()
        reader.onloadend = function (evt) {
          var data
          data = evt.target.result
          return deferred.resolve(data)
        }
        return reader.readAsText(file)
      }
    })(this)

    window.resolveLocalFileSystemURL(cordova.file.dataDirectory, gotFileSystem, fail)
    return deferred.promise()
  },
  delete: (function (_this) {
    return function (name) {
      var deferred, fail, gotFileEntry, gotFileSystem
      deferred = $.Deferred()
      fail = function (error) {
        return deferred.reject(error)
      }
      gotFileSystem = function (dir) {
        return dir.getFile(name, {
          create: false,
          exclusive: false
        }, gotFileEntry, fail)
      }
      gotFileEntry = function (fileEntry) {
        return fileEntry.remove()
      }
      window.resolveLocalFileSystemURL(cordova.file.dataDirectory, gotFileSystem, fail)
      return deferred.promise()
    }
  })(this)
}

export default Content