<template>
  <layout-auth>
    <div class="wrapper restore formwidth">
      <form class="form-login" @submit.prevent="validateBeforeSubmit()">
        <p>Письмо с временным паролем будет выслано на ваш e-mail.</p>
        <div v-if="loginErrors.length">
          <p class="LoginError" :key="key" v-for="(error,key) in loginErrors">{{ error }}</p>
        </div>
        <div class="Inputfield Inputfield_login_name">
          <input
            name="name"
            v-model="name"
            v-validate="'required|alpha_dash:en'"
            type="text"
            id="inputName"
            placeholder="Login"
          >
          <span v-show="errors.has('name')" class="help is-danger">
            <span class="small">{{ errors.first('name') }}</span>
          </span>
        </div>
        <div class="Inputfield Inputfield_isJeansLogin">
          <label for="isJeansLogin">Вы пользователь Jeans?</label>
          <custom-checkbox
            name="isJeansLogin"
            id="isJeansLogin"
            :isJeansLogin="isJeansLogin"
            v-model="isJeansLogin"
          />
        </div>
        <div class="Inputfield Inputfield_login_email">
          <input
            name="email"
            v-model="email"
            v-validate="'required|email'"
            type="text"
            id="inputEmail"
            placeholder="E-mail"
          >
          <span v-show="errors.has('email')" class="help is-danger">
            <span class="small">{{ errors.first('email') }}</span>
          </span>
        </div>
        <div class="Inputfield InputfieldSubmit" v-bind:class="{ 'active': isLoading }">
          <button class="btn btn-lg btn-primary btn-block" type="submit">Send</button>
        </div>
      </form>
      <div>
        <p>
          <a class="with-icon cancel" href="home" @click.prevent="home">Отмена</a>
        </p>
      </div>
    </div>
  </layout-auth>
</template>

<script>
import LayoutAuth from "@/Layouts/LayoutAuth.vue";
import CustomCheckbox from "./CustomCheckbox";

export default {
  name: "Restore",
  components: {
    "custom-checkbox": CustomCheckbox,
    LayoutAuth
  },
  data() {
    return {
      name: this.$route.query.username,
      email: "",
      isJeansLogin: this.$route.query.isJeansLogin == "true",
      loginErrors: [],
      wrongCredentials: false,
      isLoading: false
    };
  },
  methods: {
    home() {
      this.$router.push({ path: "/home" });
    },
    validateBeforeSubmit() {
      this.$validator
        .validateAll()
        .then(this.submit)
        .catch(function(e) {});
    },
    submit() {
      if (this.$validator.errors.items.length) return;
      this.loginErrors.splice(0);
      this.wrongCredentials = false;
      this.isLoading = true;

      this.$store
        .dispatch("restorePassword", {
          name: this.name,
          email: this.email,
          isJeansLogin: this.isJeansLogin
        })
        .then(response => this.submitSuccessful(response))
        .catch(err => this.submitFailed(err));
    },
    submitSuccessful(response) {
      this.isLoading = false;

      if (!response.data.success) {
        this.loginFailed("Нет пользователя с такими данными");
        return;
      }
      this.loginErrors.splice(0);
      this.wrongCredentials = false;
      this.$router.push({ path: "/" });
    },

    submitFailed(error) {
      this.isLoading = false;
      // this.$store
      //   .dispatch("sendstatUpdate", {
      //     name: this.name,
      //     email: this.email,
      //     isJeansLogin: this.isJeansLogin
      //   })
      //   .then(response)
      //   .catch(err);

      if (typeof error === "string") {
        this.loginErrors.push(error);
      } else if (!error.response) {
        this.loginErrors.push("Ошибка соединения");
      } else {
        // http status code
        const code = error.response.status;
        // response data
        const response = error.response.data;

        switch (code) {
          case 401:
            this.loginErrors.push("Нет пользователя с такими данными");
            this.wrongCredentials = true;
            break;
          default:
            this.loginErrors.push("Ошибка соединения (code=" + code + ")");
            break;
        }
      }
    }
  }
};
</script>
