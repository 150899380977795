<template>
<layout-auth>
  <div class="wrapper profile formwidth">
    <form class="form-login" @submit.prevent="validateBeforeSubmit()">
      <div v-if="passwordErrors.length">
        <p class="LoginError" :key="key" v-for="(error,key) in passwordErrors">{{ error }}</p>
      </div>
      <p>Новый пароль: <br />не менее 8 символов, буквы, цифры</p>
      <div class="Inputfield Inputfield_login_pass">
      <input name="password" ref="password" v-model="password" v-validate="'required|verify_password|min:8'" type="password" id="inputPassword" placeholder="Пароль" >
      <span v-show="errors.has('password')" class="help is-danger"><span class='small'>{{ errors.first('password') }}</span></span>
      </div>
      <div class="Inputfield Inputfield_login_pass">
      <input v-validate="'required|confirmed:password'" name="password_confirmation" v-model="password_confirmation" type="password" placeholder="Еще раз">
      <span v-show="errors.has('password_confirmation')" class="help is-danger"><span class='small'>{{ errors.first('password_confirmation') }}</span></span>
      </div>
      <div class="Inputfield InputfieldSubmit" v-bind:class="{ 'active': isLoading }">
      <button class="btn btn-lg btn-primary btn-block" type="submit">Log in</button>
      </div>
    </form>
  </div>
    <p>
      <a class='with-icon cancel' href='/' @click.prevent='home'>Отмена</a>
      
          <a class="with-icon logout" href="/logout" @click.prevent="logout">Выйти</a>
        </p>
      
  </layout-auth>
</template>

<script>
import LayoutAuth from "@/Layouts/LayoutAuth.vue" 

export default {
  name: 'Profile',
  components: {
    LayoutAuth
  },
  data () {
    return {
      password: '',
      password_confirmation: '',
      passwordErrors: [],
      isLoading: false
    }
  },
  methods: {
  home () {
      this.$router.push({ path: '/' })
    },
   logout() {
      this.$store.commit("logout");
      this.$router.push({ path: "/login/" });
    },

    validateBeforeSubmit () {
      this.$validator
        .validateAll()
        .then(this.sendPassword)
        .catch(function (e) {
        })
    },
    sendPassword () {
      if (this.$validator.errors.items.length) return
      this.passwordErrors.splice(0)
      this.isLoading = true

      this.$store.dispatch("changePassword", { password: this.password })
      .then(response => this.passwordChangeSuccessful(response))
      .catch(err => this.passwordChangeFailed(err))
    },

    passwordChangeSuccessful(req) {
      this.isLoading = false
      if (req.data.success == true) {
        this.$router.push({ path: '/' })
      } else {
        this.passwordChangeFailed('Не удалось изменить пароль')
      }
    },

    passwordChangeFailed (error) {
      this.isLoading = false
      if (typeof error === 'string') {
        this.passwordErrors.push(error)
      } else if (!error.response) {
        this.passwordErrors.push('Ошибка соединения')
      } else {
        // http status code
        const code = error.response.status
        // response data
        const response = error.response.data

        switch (code) {
          case 401: this.passwordErrors.push('Авторизация не прошла'); break
          default: this.passwordErrors.push('Ошибка соединения (code=' + code + ')'); break
        }
      }
    }
  }
}
</script>


