<template>
  <div id="app" class="vh-100 w-100 bg-black white">
    <transition appear name="fade" mode="in-out">
      <router-view /> 
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() { return {
      layout: 'div',
    }
  }
}
</script>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

</style>
