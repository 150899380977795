import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as CONFIG from '@/config';
var hash = require('object-hash');

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const store = new Vuex.Store({
  plugins: [createPersistedState({ storage: window.cordova ? window.localStorage : window.sessionStorage })],
  state: {
    authenticated: false,
    cordova: window.cordova ? true : false,
    token: null,
    passwordHash: null,
    userData: null,
    region: null,
    channel: 'off-trade',
    toc: null,
    updates: {},
    prevModified: 0,
    modified: 0,
    appToken: null,
    appUserID: 0,
    packageTS: [],
    downloadsStartedCount: 0
  },
  mutations: {
    clearState(state) {
      state.userData = state.token = null;
      state.authenticated = false;
      state.passwordHash = null;
      state.region = null;
      state.toc = null;
      state.updates = {};
      state.modified = 0;
      state.prevModified = 0;
      state.packageTS = [];
      window.downloadsStartedCount = 0;
    },
    setTOC(state, { data }) {
      state.toc = data.toc;
      if (data.modified) store.commit("updateModificationTime", { ts: data.modified })
    },
    setUpdates(state, { updates }) {
      Object.entries(updates).forEach(([r, region]) => {
        // console.log("region " + r);
        if (!state.updates.hasOwnProperty(r)) state.updates[r] = {}
        Object.entries(region).forEach(([c, chapter]) => {
          // console.log("chapter " + c);
          if (!state.updates[r].hasOwnProperty(c)) state.updates[r][c] = []
          state.updates[r][c] = [...new Set([...state.updates[r][c], ...chapter])];
        })
      })
    },
    deletePageFromUpdates(state, { pageID }) {
      let findDeep = function (data, value) {
        if (Array.isArray(data)) {
          let ind = data.indexOf(value);
          if (ind >= 0) {
            data.splice(ind, 1);
          }
        } else {
          for (let i = 0; i < Object.keys(data).length; i++) {
            // console.log(i);
            findDeep(data[Object.keys(data)[i]], value)
          }

        }
      }
      findDeep(store.state.updates, pageID);
    },
    setActiveRegion(state, {
      region
    }) {
      state.region = region;
    },
    setUserData(state, {
      token,
      userData,
      passwordHash
    }) {
      state.userData = userData;
      state.passwordHash = passwordHash;
      state.token = token;
      state.authenticated = true;
    },
    setAppToken(state, { appToken }) {
      state.appToken = appToken;
    },
    resetToken(state) {
      state.token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiIxOTIuMTY4LjEuMTIzIiwiYXVkIjoiMTkyLjE2OC4xLjEyMyIsImlhdCI6MTU3NTk3MjY1MCwibmJmIjoxNTc1OTcyNjUwLCJleHAiOjE1NzYwNTkwNTAsInVzZXJJZCI6NTE0NjF9.7EJ9bdJ-NvzkrlHcQOeQ9YLtIag1ldJ-RBIjnMu9sf4";
    },
    setAppUserID(state, { appUserID }) {
      state.appUserID = appUserID;
    },
    clearAppToken(state) {
      state.appToken = null;
      state.appUserID = 0;
    },
    updateModificationTime(state, { ts }) {
      state.modified = ts;
    },
    setPackageTS(state, { ts }) {
      state.packageTS.push(ts);
    },
    clearPackageTS(state) {
      state.packageTS = [];
    },
    increaseDownloadsStartedCount(state) {
      window.downloadsStartedCount = state.downloadsStartedCount + 1;
    },
    decreaseDownloadsStartedCount(state) {
      window.downloadsStartedCount = state.downloadsStartedCount - 1;
    },
    resetAuth(state, {
      auth
    }) {
      if (!auth) state.userData = null;
      state.authenticated = auth;
    }
  },
  actions: {
    login({
      commit
    }, {
      username,
      password,
      cordova,
      isJeansLogin,
      channel
    }) {

      return new Promise((resolve, reject) => {
        // console.log("modified: " + this.state.modified + " passwodHash: " + this.state.passwordHash + " online: " + window.navigator.onLine);
        // Обработка случая залогинивания в режиме оффлайн и с правильным паролем
        if (!window.navigator.onLine && this.state.passwordHash && this.state.passwordHash == hash(password)) {
          console.log("user offline");
          if (this.state.modified !== 0) {
            commit('resetAuth', { auth: true });
            resolve();
          } else reject();
        } else {
          console.log("user auth sending");
          axios.defaults.baseURL = CONFIG.USERS_API_URL;
          axios.post('/auth', {
            password: password,
            cordova: cordova,
            username: username,
            isJeansLogin: isJeansLogin,
            channel: channel
          })
            .then(response => {
              const token = response.data.jwt;
              if (!token) { reject(new Error('Ошибка авторизации')); }
              axios.defaults.headers.Authorization = 'Bearer ' + token;
              axios.get('/user')
                .then(response => {
                  commit('setUserData', {
                    token: token,
                    passwordHash: hash(password),
                    userData: response.data
                  });
                  resolve();
                })
                .catch(err => {
                  reject(err);
                });
            })
            .catch(err => {
              reject(err);
            });
        }
      });
    },

    logout({
      commit
    },
      { keepUser }) {
      return new Promise((resolve, reject) => {
        if (typeof keepUser !== "undefined" && keepUser) {
          commit('resetAuth', { auth: false });
          resolve();
        } else {
          if (window.cordova) this.dispatch('unRegisterAppUser').finally(() => {
            commit("clearAppToken");
            commit("clearState");
            resolve();
          });
          else {
            commit("clearState");
            resolve();
          }
        }
      })
    },

    sendContentLastUpdateTime({
      commit
    }, {
      ts
    }) {
      return new Promise(() => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = 'Bearer ' + this.state.token;
        axios.put('/app', {
          auid: this.state.appUserID,
          ts: ts
        })
          .finally(() => {
          })
      })
    },

    sendStatUpdate({
      commit
    }, {
      data
    }) {
      return new Promise((resolve, reject) => {
        let now = new Date();
        data.date = now;
        data.cordova = this.state.cordova;
        data = data.username ? data.username : this.state.userData;
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = 'Bearer ' + this.state.token;
        axios.put('/stats', {
          data: data
        })
          .then(result => resolve(result))
          .catch(err => reject(err))
      })
    },

    registerAppUser({
      commit
    }, {
      appToken
    }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = 'Bearer ' + this.state.token;
        let registrationData = {
          uid: this.state.userData.userID,
          token: appToken,
          device: JSON.stringify(device)
        }
        if (this.state.appUserID) registrationData.auid = this.state.appUserID;
        axios.put('/app', registrationData)
          .then(response => {
            commit('setAppToken', { appToken });
            const data = JSON.parse(response.data.message);
            if (data.auid) commit('setAppUserID', { appUserID: data.auid });
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    },

    unRegisterAppUser({
      commit
    }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = 'Bearer ' + this.state.token;
        axios.delete('/app', {
          data: {
            auid: this.state.appUserID
          }
        })
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    changePassword({
      commit
    }, {
      password
    }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = 'Bearer ' + this.state.token;
        axios.put('/user', {
          newPassword: password
        })
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    },



    restorePassword({
      commit
    }, {
      name,
      email,
      isJeansLogin
    }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.USERS_API_URL;
        axios.defaults.headers.Authorization = 'Bearer ' + this.state.token;
        axios.post('/user', {
          name: name,
          email: email,
          isJeansLogin: isJeansLogin
        })
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            reject(err);
          });
      });
    },


    checkForMods({
      commit
    }) {
      return new Promise((resolve, reject) => {
        axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
        axios.defaults.headers.Authorization = 'Bearer ' + this.state.token;
        console.log("before-checkformods, channel: " + this.state.channel + ",userRegion: " + this.state.userData.userRegion.map(a => a.value) + ",ts: " + this.state.modified + ",token: " + this.state.token);
        axios.get('/modified', {
          params: {
            channel: this.state.channel,
            userRegion: this.state.userData.userRegion.map(a => a.value),
            ts: this.state.modified
          }
        })
          .then(response => {
            console.log("server stamp modified");
            console.log(response.data);
            console.log("this.state.modified");
            console.log(this.state.modified);
            if (typeof response.data.updates !== "undefined") commit('setUpdates', { updates: response.data.updates });
            const result = Number(response.data.modified) > Number(this.state.modified);
            console.log("RESULT: " + result);
            resolve(result);
          })
          .catch(err => {
            console.log("false-insidecheckformods: " + err);
            reject(err);
          });
      });
    },

    fetchTOC({
      commit
    }) {
      return new Promise((resolve, reject) => {
        if (window.cordova) {
          window.resolveLocalFileSystemURL(cordova.file.dataDirectory + "toc.json",
            function (fileEntry) {
              console.log("path2file: " + cordova.file.dataDirectory + "toc.json");
              fileEntry.file(function (file) {
                var reader = new FileReader();
                reader.onloadend = function () {
                  // console.log("readerend: " + this.result);
                  resolve(this.result);
                };
                reader.onerror = function () {
                  // console.log("readererror: " + this.error);
                  reject(this.error);
                }
                reader.readAsText(file);
              });
            },
            function (e) {
              reject(e);
            }
          );
        } else if (window.navigator.onLine) {
          console.log("from server");
          axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
          axios.defaults.headers.Authorization = 'Bearer ' + this.state.token;
          axios.get('/toc', {
            params: {
              userRegion: this.state.userData.userRegion.map(a => a.value),
              channel: this.state.channel
            }
          })
            .then(response => {
              store.commit('setTOC', {
                data: response.data
              });
              resolve();
            })
            .catch(err => {
              reject(err);
            });
        } else {
          reject();
        }

      });
    },

    getOfflineContent({
      commit
    }) {

      return new Promise((resolve, reject) => {
        window.plugins.insomnia.keepAwake();
        axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;
        axios
          .post("/offline", {
            regions: JSON.stringify(this.state.userData.userRegion.map(a => a.value)),
            last: this.state.modified
          })
          .then(response => {
            window.plugins.insomnia.allowSleepAgain();
            resolve(response);
          })
          .catch(err => {
            window.plugins.insomnia.allowSleepAgain();
            reject(err);
          })
      });
    },

    deletePackage({
      commit
    }) {
      return new Promise((resolve, reject) => {

        axios.defaults.baseURL = CONFIG.CONTENT_API_URL;
        axios.defaults.headers.Authorization = "Bearer " + this.state.token;
        axios
          .delete("/offline", {
            data: { ts: this.$store.state.packageTS }
          })
          .finally(response => {
            commit("clearPackageTS");
            resolve(response);
          });
      });
    }

  }
});

export default store;
