import Vue from 'vue'
import App from './App'

import router from './router'
import store from './store'

import SuiVue from 'semantic-ui-vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VeeValidate, { Validator } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru'

import babelPolyfill from '@babel/polyfill'

require('swiper/dist/css/swiper.css')
require('./scss/styles.scss');

var values = require('object.values');
if (!Object.values) {
    values.shim();
}

// if (!String.prototype.includes) {//To check browser supports includes() or not
//   String.prototype.includes = function (str) {//If not supported, then define the method
//     return this.indexOf(str) !== -1;
//   }
// }

Vue.use(VueAxios, axios)

Validator.extend('verify_password', {
  getMessage () { return 'Поле должно содержать буквы и цифры' },
  validate (value) {
    var strongRegex = new RegExp('^(?=.*[a-zA-z])(?=.*[0-9])')
    return strongRegex.test(value)
  }
})
Vue.use(VeeValidate)
Validator.localize('ru', ru)

Vue.use(SuiVue)

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    resolveAssetPath (path) {
      return this.$CONTENT_API_URL + path;
    }
  }
});

const MyApp = {
  initialize() {
    if (window.cordova) {
      document.addEventListener('deviceready', this.initVue, false);
    } else {
      this.initVue();
    }
  },

  initVue() {
  window.vm = new Vue({
    babelPolyfill,
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
}
}

MyApp.initialize();




